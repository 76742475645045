/* eslint-disable */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import axios from 'axios';
import { validated } from 'react-custom-validation';
import update from 'immutability-helper';
import FacebookLogin from 'react-facebook-login';
import { setMinutes, setHours, getDay, format } from 'date-fns';
var dateFormat = require('dateformat');

import { appId, apiUrl, apiUrlV2, apiUrlPro, deliveryId, pickupId, cateringId, reservationId, baseUrl, fbAppId } from "../Helpers/Config";

import { GET_GLOBAL_SETTINGS, GET_ZONE_DETAIL, GET_PICKUP_OUTLETS, GET_ALL_OUTLETS, DESTROY_CART_DETAIL, GET_LOGINDATA, GET_FBLOGINDATA, GET_FORGET_PASSWORD, GET_REGISTRATION , GET_MENUDATA, GET_ALLUSERSECADDRDATA} from '../../actions';
import { getReferenceID, showAlert, showLoader, hideLoader, getAliasName, stripslashes, removeOrderDateTime, removePromoCkValue, addressFormat } from "../Helpers/SettingHelper";

import MenuNavigationmob from "./MenuNavigation/MenuNavigationmob";
import OrderdatetimeSlot from "./OrderdatetimeSlot";
import {Login,Forgotpassword,Signup,Guestcheckout} from "../../components/Myaccount/Index";

/* import images */
import mainLogo from "../../common/images/logo.png";
import footerLogo from "../../common/images/logo.png";
import mobileLogo from "../../common/images/res-logo.svg";
/*import takeawayimage from "../../common/images/takeaway.svg";*/
import iconUnhappy from "../../common/images/sad-smiley.png";
import iconWin from "../../common/images/icon-win.svg";
import takeawayOld from "../../common/images/takeaway_old.png";
import userProfile from "../../common/images/user-profile.png";
import warningImg from "../../common/images/warning.svg";


import deliveryImg from "../../common/images/delivery.svg";
import deliveryWtImg from "../../common/images/delivery-w.svg";
/*import takeawayImg from "../../common/images/takeaway.svg";*/
import takeawayImg from "../../common/images/takeaway.svg";
import takeawayWtImg from "../../common/images/takeaway-w.svg";
import cateringImg from "../../common/images/catering.svg";
import cateringWtImg from "../../common/images/catering-w.svg";
import reservationImg from "../../common/images/reservation.svg";
import loaderimg from "../../common/images/search-load.gif";

import CartSideBar from "./CartSideBar";

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            seletedAvilablityId:'',seletedOutletId:'',order_tat_time:0,globalsettings: [], secondaryaddresslist: [], deliveryOutlets: [], deliveryOutletsList: [], pickupOutlets: [], pickupOutletsList: [], delivery_outlet_id:'', searchProResult: [], selectedProResult: [], orderHandled: '', orderDeliveryAddress: '', nextavail: '', logindata:'', getDateTimeFlg:'', deliveryInfo: [],pickupInfo: [],seleted_ord_date: '',seleted_ord_time: '', fields: {
            email: '',
            password: ''
			},
			fieldsfgtpassword: {
				email: ''
			},
			fieldscheckout: {
                check_email: '',
                check_firstname: '',
                check_phone: '',
                birthday: '',
            },
			fpstatus:'initiating',
			regstatus:'initiating',
			fieldssignup: {
				firstname: '',
				lastname: '',
				email: '',
				birthday: '',
				gender: 'M',
				password: '',
				rePassword: '',
				mobile: '',
				pdpa_consent: '',
				terms: ''

			},
              menuData:[],
        };
		
		this.props.getGlobalSettings();
		this.props.getPickupOutlets();
		this.props.getAllOutlets(deliveryId);
		this.props.getSecondaryAddress();
		
		var availbty = cookie.load('defaultAvilablityId');
		var outltIdTxt = (typeof cookie.load('orderOutletId') === 'undefined') ? '' : cookie.load('orderOutletId');
		var zoneIdTxt = (typeof cookie.load('orderZoneId') === 'undefined') ? '' : cookie.load('orderZoneId');
		
		if(availbty === deliveryId && outltIdTxt !== '' && zoneIdTxt !== '') {
			this.state['delivery_outlet_id'] = outltIdTxt;
			this.props.getZoneDetail(outltIdTxt, zoneIdTxt);
		}
    }
	
	/*forget password  - start*/
	fieldforgot = (field, value) => {
		this.setState(update(this.state, { fieldsfgtpassword: { [field]: { $set: value } } }))
    }
    
    forgotpassword = () => {
        this.setState({fpstatus:'loading'});
        const formPayload = this.state.fieldsfgtpassword;

        var qs = require('qs');
        var postObject = {
            "app_id": appId,
            "type": "web",
            "email_address": formPayload.email,
           "site_url": this.props.globalsettings[0].result_set.client_site_url,
        };
		
	   showLoader('forgotpassword-cls','class');
       this.props.getForgetPassword(qs.stringify(postObject));
    }
	/*forget password  - end*/
	
	/* signin - start*/
	fieldChange = (field, value) => {
		this.setState(update(this.state, { fields: { [field]: { $set: value } } }))
	}
	
	handleSignin = () => {
		const formPayload = this.state.fields;
		var qs = require('qs');
		var postObject = {
			"app_id": appId,
			"type": "web",
			"login_username": formPayload.email,
			"login_password": formPayload.password
		};
		showLoader('login_submit','class');
		this.props.getLoginData(qs.stringify(postObject));

	}
   /* signin - end*/
   
   
   /* for signup - start*/
	fieldChangeSignup = (field, value) => {
		if (field == 'terms') {
			value = $("#terms").prop("checked");
		}
		if (field == 'pdpa_consent') {
			value = $("#pdpa_consent").prop("checked");
		}
		this.setState(update(this.state, { fieldssignup: { [field]: { $set: value } } }))
    }
     
    handleSignup = () => {
        
        const formPayload = this.state.fieldssignup;
        this.setState({regstatus:'loading'});
		
		var pdpaConsent = (formPayload.pdpa_consent === true) ? 'yes' : 'no';
        
        var qs = require('qs');
        var postObject = {
            "app_id": appId,
            "type": "web",
            "customer_first_name": formPayload.firstname,
            "customer_last_name": formPayload.lastname,
            "customer_email": formPayload.email,
            "customer_password": formPayload.password,
            "customer_phone": formPayload.mobile,
            "customer_gender": formPayload.gender,
            "customer_birthdate": formPayload.birthday,
            "customer_pdpa_consent": pdpaConsent,
            "site_url": this.props.globalsettings[0].result_set.client_site_url,
        };
		
		showLoader('signup_submit','class');
        this.props.getRegistration(qs.stringify(postObject));
 
    }
	/* for signup - end*/
   

	componentWillReceiveProps(PropsDt) {
        
         if(PropsDt.menudata !== this.props.menudata){
         this.setState({menudata: PropsDt.menudata[0].result_set});
         }
        
		if(PropsDt.outletslist !== this.state.pickupOutletsList) {
		  this.setState({pickupOutlets: PropsDt.outletslist,pickupOutletsList:PropsDt.outletslist});
		}
		
		if(PropsDt.alloutletslist !== this.state.deliveryOutletsList) {	
		  this.setState({deliveryOutlets: PropsDt.alloutletslist,deliveryOutletsList:PropsDt.alloutletslist});
		}
		
		if(PropsDt.secondaryaddresslist !== this.state.secondaryaddresslist) {
		  this.setState({secondaryaddresslist: PropsDt.secondaryaddresslist});
		}
		
		if('homePageState' in PropsDt) {
			if(PropsDt.homePageState.nextavail !== undefined && PropsDt.homePageState.nextavail !== '' && PropsDt.homePageState.nextavail !== this.state.nextavail) {
				this.setState({nextavail: PropsDt.homePageState.nextavail});
			}
		}
		
		if(PropsDt.fbloginData!==this.props.fbloginData){
			this.doLogin(PropsDt.fbloginData);
        }
		
        if(PropsDt.logindata !==this.props.logindata){
            this.doLogin(PropsDt.logindata[0]);
        }

		if(this.state.fpstatus==='loading'){
            if(PropsDt.forgetpassword !== undefined){
               this.setState({fpstatus:'ok'});
               this.showMessage(PropsDt.forgetpassword[0])       
            }
		}
          
        if(this.state.regstatus==='loading'){
            if(PropsDt.registration !== undefined){
                this.setState({regstatus:'ok'});
                this.showMessage(PropsDt.registration[0])       
            }
        }
	}
	

   /* main - menu navigation -start*/
	
	createLink(menu){
		if(menu.nav_type==="0" && menu.nav_parent_title == '')
		{
	     return  <Link to={"/page/"+menu.nav_title_slug} title={menu.nav_title}><span>{menu.nav_title}</span></Link>;
		}
		else if(menu.nav_type==="3" &&  menu.nav_parent_title == '')
		{  
		   var pageUrlTxt = menu.nav_pages;	
	       if(pageUrlTxt.includes('http')) {
			   return (<a href={menu.nav_pages} title={menu.nav_title} target={ (menu.nav_link_type == 'blank') ?'_blank':''}><span>{menu.nav_title}</span></a>);
		   } else {
			   return   <Link to={(menu.nav_pages != '#') ?"/"+menu.nav_pages:''} title={menu.nav_title} target={ (menu.nav_link_type == 'blank') ?'_blank':''}><span>{menu.nav_title}</span></Link>;
		   }
           
		}
		
    }
    
    createSubmenu(menu,type){
		if(menu.nav_parent_title === '')
        {
		  if (this.state.menudata) {
			var checkIngVal = 0;  
            var liTxt =  this.state.menudata.map(function (menuparent, i) {
		
			   if(menu.nav_id == menuparent.nav_parent_title){
				  checkIngVal = 1;
				  if(menuparent.nav_type==="0") {
                  return  (<li key={i+100}><Link to={"/page/"+menuparent.nav_title_slug} title={menuparent.nav_title}><span>{menuparent.nav_title}</span></Link></li>);
				  } else {
				  return  (<li key={i+100}><Link to={(menuparent.nav_pages != '#') ?"/"+menuparent.nav_pages:''} title={menuparent.nav_title} target={ (menuparent.nav_link_type == 'blank') ?'_blank':''}><span>{menuparent.nav_title}</span></Link></li>);  
				  }
            }},this);
			
			if(type === 'span' && checkIngVal === 1) {
				return (<a href="/" className="submenu-arow disbl_href_action"></a>);
			} else if(type === 'ul' && checkIngVal === 1) {
				return (<ul className="submenu_list">{liTxt}</ul>);
			} else {
				return '';
			}
			
         }
		}
	}
	
	menuActiveCls(nav_pages) {
		var currenturl = window.location.href;
		var returnClsTx = '';
		if(nav_pages === 'home' || nav_pages === '#') {
			returnClsTx = (this.props.match.path === '/home' || this.props.match.path === '/') ? "active" : '';
		} else if(nav_pages === 'products') {
			returnClsTx = (this.props.match.path === '/products' || this.props.match.path === '/checkout' || this.props.match.path === '/thankyou') ? "active" : '';
		} else {
			returnClsTx = currenturl.includes(nav_pages) ? "active" : '';
		}
		return returnClsTx;
	}


	listMainNavigation(){
		   if (this.state.menudata) {
           return this.state.menudata.map(function (menu, i) {
                  return  (<li key={i+100} className={this.menuActiveCls(menu.nav_pages)}>{this.createLink(menu)}{this.createSubmenu(menu,'span')}{this.createSubmenu(menu,'ul')}</li>);
            },this)
         }
      }
	
	/* menu navigation -end */ 
	/* show message */
	showMessage(response){
           hideLoader('signup_submit','class');
           hideLoader('forgotpassword-cls','class');
           if (response.status === "ok") {
              showAlert('Success', 'Mail has been sent');
            } else {
                if (response.form_error) {
                   showAlert('Error', response.form_error);
                 } else {
                    showAlert('Error', response.message);
                 }
            }
            $.magnificPopup.open({
				items: {
				  src: '.alert_popup'
			    },
			    type: 'inline'
			});
    }
	
	chooseAvailabilityFun(availability, event){

	    event.preventDefault();
		var defaultAvilTy = cookie.load('defaultAvilablityId');
	
		if(defaultAvilTy!==availability){
		  var cartTotalItems = cookie.load('cartTotalItems');
			  cartTotalItems = (cartTotalItems != '' && cartTotalItems != undefined) ? parseInt(cartTotalItems) : 0;
		  if(defaultAvilTy === cateringId && cartTotalItems > 0) {
			  this.setState({nextavail:availability});
			  $.magnificPopup.open({
				items: {
				  src: '#warning-popup'
				},
				type: 'inline'
			  });
			  return false;
		  } else if(cookie.load('orderOutletId') != '' && cookie.load('orderOutletId') != undefined) {
			  this.setState({nextavail:availability});
			  $.magnificPopup.open({
				items: {
				  src: '#warning-popup'
				},
				type: 'inline'
			  });
			  return false;
		  }
		} else if(defaultAvilTy===availability && cookie.load('orderOutletId') != '' && cookie.load('orderOutletId') != undefined){
			/*$.magnificPopup.close();
			this.props.history.push('/products');
			return false;*/
		}
		
		var popupIdtxt = '';
		if(availability === deliveryId){
		  $('.delivery_outletpoup').find(".outlet_error").html('');
		  popupIdtxt = '#delevery-popup';
		}else if(availability === pickupId){
		  popupIdtxt = '#takeaway-popup';
		} else if(availability === cateringId) {
			cookie.save("defaultAvilablityId",cateringId);
		  	$.magnificPopup.close();
			this.props.history.push('/catering');
			return false;
		} else if(availability === reservationId) {
			cookie.save("defaultAvilablityId",reservationId);
		  	$.magnificPopup.close();
			this.props.history.push('/reservation');
			return false;
		    /*popupIdtxt = '#comingsoon-popup';*/
		}
		
		if(popupIdtxt !== '') {
			$.magnificPopup.open({
				items: {
				  src: popupIdtxt
				},
				type: 'inline'
			});
		}
	  

	}
	
	chooseAvailabilityMbl() {
		var availability = cookie.load('defaultAvilablityId');
		var showtext = 'Order Now';
		if(availability === deliveryId){
			showtext = 'Delivery';
		}else if(availability === pickupId){
			showtext = 'Takeaway';
		} else if(availability === cateringId) {
			showtext = 'Catering';
		} else if(availability === reservationId) {
			showtext = 'Reservation';
		}
		return showtext;
	}
	
	/* facebook login */
	responseFacebook = (response) => {
		if (response.name !== "" && response.email !== "" && response.name !== undefined && response.email !== undefined) {
			var vSplitName = response.name.split(" ");
			var firstname = vSplitName[0];
			var lastname = vSplitName[1];
			var birthday = response.birthday;
			var qs = require('qs');
			var photo = response.picture.data.url;
			/* update gender field */
			var postGender = "";
			if (typeof response.gender !== "undefined" && response.gender === "male") {
				postGender = "M";
			} else if (typeof response.gender !== "undefined" && response.gender === "female") {
				postGender = "F";
			}
			/* update DOB */
			var dob = '';
			if (typeof birthday !== "undefined" && birthday !== "") {
				dob = dateFormat(response.birthday, "yyyy-mm-dd");
			}



			var postObject = {
				"app_id": appId,
				"type": "web",
				"login_firstname": response.first_name,
				"login_lastname": response.last_name,
				"login_username": response.email,
				"customer_fb_id": response.id,
				"customer_gender": postGender,
				"customer_photo": photo,
				"customer_dob": dob
			};
			this.props.getFbLoginData(qs.stringify(postObject));
			
		}
	}

   /* for login and facebook login*/
   doLogin(fbloginData){
	   hideLoader('login_submit','class');
	   if (fbloginData.status === "ok") {
			$.magnificPopup.close();
			var mobileno = '', cust_birthdate = '';
			if (typeof fbloginData.result_set.customer_phone === "undefined" || fbloginData.result_set.customer_phone === "null" || fbloginData.result_set.customer_phone === "") {
				mobileno = "";
			} else {
		
				mobileno = fbloginData.result_set.customer_phone;
			}
			
			if (typeof fbloginData.result_set.customer_birthdate !== "undefined" && fbloginData.result_set.customer_birthdate !== "null" && fbloginData.result_set.customer_birthdate !== null && fbloginData.result_set.customer_birthdate !== "" && fbloginData.result_set.customer_birthdate !== "0000-00-00") {
				cust_birthdate = fbloginData.result_set.customer_birthdate;
			}
		
			cookie.save("UserId", fbloginData.result_set.customer_id);
			cookie.save("UserEmail", fbloginData.result_set.customer_email);
			cookie.save("UserFname", (fbloginData.result_set.customer_first_name !== "" ? fbloginData.result_set.customer_first_name : ''));
			cookie.save("UserLname", (fbloginData.result_set.customer_last_name !== "" ? fbloginData.result_set.customer_last_name : ''));
			cookie.save("UserMobile", mobileno);
			cookie.save("UserBirthdate", cust_birthdate);
		
			cookie.save("UserDefaultAddress", fbloginData.result_set.customer_address_name);
			cookie.save("UserDefaultUnitOne", fbloginData.result_set.customer_address_line1);
			cookie.save("UserDefaultUnitTwo", fbloginData.result_set.customer_address_line2);
			cookie.save("UserDefaultPostalCode", fbloginData.result_set.customer_postal_code);

			const{history} = this.props;
			
		   if(cookie.load('cateringPay') === 'start') {
				
				showAlert('Success', 'Logged in Successfully!');
				$.magnificPopup.open({
					items: {
						src: '.alert_popup'
					},
					type: 'inline'
				});
				
				history.push("/payment/"+cookie.load('orderIdTxt')+"/"+cookie.load('paymentIdTxt'));
				/*history.push("/payment");*/
				
		  } else {

			var qs = require('qs');
			var postObject = {
				"app_id": appId,
				"reference_id": getReferenceID(),
				"customer_id": fbloginData.result_set.customer_id,
				"availability_id": cookie.load('defaultAvilablityId')
			};

			axios.post(apiUrl + "cart/update_customer_info", qs.stringify(postObject)).then(res => {
			    showAlert('Success', 'Logged in Successfully!');
				$.magnificPopup.open({
					items: {
						src: '.alert_popup'
					},
					type: 'inline'
				});
				if (res.data.status === "ok") {
					if(cookie.load('loginpopupTrigger')==='fromcheckout') {
					  cookie.remove('loginpopupTrigger');
					  history.push("/checkout");
					} else if(cookie.load('loginpopupTrigger')==='fromchecatering') {
					  cookie.remove('loginpopupTrigger');
					  history.push("/catering");  
				    } else if(cookie.load('redirectReservatin')==='Yes') {
					  cookie.remove('redirectReservatin');
					  history.push("/reservation");  
					} else {
					  history.push("/myaccount");
					}
				} else {
					if(cookie.load('redirectReservatin')==='Yes') {
					  cookie.remove('redirectReservatin');
					  history.push("/reservation");  
					} else {
						history.push("/myaccount");
					}
				}
			});
			
		 }

		} else {
			cookie.remove('loginpopupTrigger');
			/*showAlert('Error', 'Invalid Login Credentials','trigger_login','#login-popup');*/
			showAlert('Error', 'Invalid Login Credentials');
			$.magnificPopup.open({
				items: {
					src: '.alert_popup'
				},
				type: 'inline'
			});
		}
	}
	
	/*  Onchange  for Guest Checkout */
    fieldChangecheckout = (field, value) => {
        this.setState(update(this.state, { fieldscheckout: { [field]: { $set: value } } }))
    }
	
	handleCheckout = () => {
        const formPayload = this.state.fieldscheckout;
        if (document.getElementById("spn-email-error").innerHTML === '<span class="error">This email already exists</span>') {
            return false;
        }
        if (document.getElementById("spn-mobile-error").innerHTML === '<span class="error">This mobile number already exists</span>') {
            return false;
        }
		
		showLoader('login_in_guest','class');
		
        var qs = require('qs');
        var postObject = {
            "app_id": appId,
            "type": "web",
            "customer_first_name": formPayload.check_firstname,
            "customer_email": formPayload.check_email,
            "customer_phone": formPayload.check_phone,
            "customer_birthdate": formPayload.birthday,
            "site_url": this.props.globalsettings[0].result_set.client_site_url,
        };
        
        axios.post(apiUrl + "guestaccount/create", qs.stringify(postObject))
            .then(response => {
                hideLoader('login_in_guest','class');
                if (response.data.status === "ok") {
                    window.scrollTo(0, 0);
                    $.magnificPopup.close();
                   
				    var cust_birthdate = '';
				    if (typeof response.data.result_set.customer_birthdate !== "undefined" && response.data.result_set.customer_birthdate !== "null" && response.data.result_set.customer_birthdate !== null && response.data.result_set.customer_birthdate !== "" && response.data.result_set.customer_birthdate !== "0000-00-00") {
						cust_birthdate = response.data.result_set.customer_birthdate;
					}
				    console.log('cust_birthdate', cust_birthdate);
				    console.log('wwwwwww', response.data.result_set.customer_birthdate);
                    /* set User cookie values - Start */
                    cookie.save("UserId", response.data.result_set.customer_id);
                    cookie.save("UserFname", (response.data.result_set.customer_first_name !== "" ? response.data.result_set.customer_first_name : ''));
                    cookie.save("UserLname", (response.data.result_set.customer_last_name != "" ? response.data.result_set.customer_last_name : ''));
                    cookie.save("UserMobile", response.data.result_set.customer_phone);
                    cookie.save("UserEmail", response.data.result_set.customer_email);
                    cookie.save("UserDefaultAddress", response.data.result_set.customer_address_name);
                    cookie.save("UserDefaultUnitOne", response.data.result_set.customer_address_line1);
                    cookie.save("UserDefaultUnitTwo", response.data.result_set.customer_address_line2);
                    cookie.save("UserDefaultPostalCode", response.data.result_set.customer_postal_code);
                    cookie.save("UserBirthdate", cust_birthdate);
                    cookie.save("userAccountType",1);
					const{history} = this.props;
					
					if(cookie.load('cateringPay') === 'start') {
						showAlert('Success', 'Logged in Successfully!');
						$.magnificPopup.open({
							items: {
								src: '.alert_popup'
							},
							type: 'inline'
						});
						history.push("/payment/"+cookie.load('orderIdTxt')+"/"+cookie.load('paymentIdTxt'));
						/*history.push("/payment");*/
				    } else {

                    var qs = require('qs');
                    var postObject = {
                        "app_id": appId,
                        "reference_id": getReferenceID(),
                        "customer_id": response.data.result_set.customer_id,
                        "availability_id": cookie.load('defaultAvilablityId')
                    };

                    axios.post(apiUrl + "cart/update_customer_info", qs.stringify(postObject)).then(res => {
						 showAlert('Success', 'Logged in Successfully!');
						 $.magnificPopup.open({
							items: {
								src: '.alert_popup'
							},
							type: 'inline'
						 });
                        if (res.data.status === "ok") {
                            if(cookie.load('loginpopupTrigger')==='fromcheckout') {
							  cookie.remove('loginpopupTrigger');
							  history.push("/checkout");
							} else if(cookie.load('loginpopupTrigger')==='fromchecatering') {
							  cookie.remove('loginpopupTrigger');
							  history.push("/catering");  
							} else if(cookie.load('redirectReservatin')==='Yes') {
							  cookie.remove('redirectReservatin');
							  history.push("/reservation");  
							} else {
							  history.push("/myaccount");
							}
                        }
                        else {
                            if(cookie.load('redirectReservatin')==='Yes') {
							  cookie.remove('redirectReservatin');
							  history.push("/reservation");  
							} else {
								history.push("/myaccount");
							}
                        }
                    });

					}
                    

                } else {
                    if (response.data.form_error) {
                        $('.guest-chk-error').html('<div class="alert alert_danger" style="display:block">'+response.data.form_error+'</div>');

                    } else {

                        $('.guest-chk-error').html('<div class="alert alert_danger" style="display:block">'+response.data.message+'</div>');

                    }
                }
                return false;
            })
            .catch(function (error) {
                console.log(error);
            });
    }
	
	closepopup(event) {
		event.preventDefault();
		
		$.magnificPopup.open({
		  items: {
			src: '#order-popup'
		  },
		  type: 'inline'
		});
	}
	
	gobckOutletpopup() {
		$.magnificPopup.open({
		  items: {
			src: '#delevery-popup'
		  },
		  type: 'inline'
		});
	}
	
	gobckPkupOutletpopup() {
		$.magnificPopup.open({
		  items: {
			src: '#takeaway-popup'
		  },
		  type: 'inline'
		});
	}
	
	changeAvailability() {
		
		var tempArr = [], tempVl = '';
		this.setState({seletedOutletId:tempVl,deliveryInfo:tempArr,pickupInfo:tempArr,seleted_ord_date:tempVl,seleted_ord_time:tempVl});
		
		this.destroyCart("Yes");
		
		var popupIdtxt = '';
		if(this.state.nextavail === deliveryId){
		  popupIdtxt = '#delevery-popup';
		}else if(this.state.nextavail === pickupId){
		  popupIdtxt = '#takeaway-popup';
		} else if(this.state.nextavail === cateringId) {
			cookie.save("defaultAvilablityId",cateringId);
		  	$.magnificPopup.close();
			this.props.history.push('/catering');
			return false;
		} else if(this.state.nextavail === reservationId) {
			cookie.save("defaultAvilablityId",reservationId);
		  	$.magnificPopup.close();
			this.props.history.push('/reservation');
			return false;
		    /*popupIdtxt = '#comingsoon-popup';*/
		}
		
		if(popupIdtxt !== '') {
			$.magnificPopup.open({
				items: {
				  src: popupIdtxt
				},
				type: 'inline'
			});
		}
	}
	
	destroyCart(clear='No') {
		this.props.destroyCartDetail();
		this.deleteOrderCookie(clear);
	}
	
	deleteOrderCookie(clear='Yes') {
		if(clear == 'Yes'){
			cookie.remove('orderZoneId');
			cookie.remove('orderOutletId');
			cookie.remove('outletchosen');        
		}
		
		removeOrderDateTime();
	    removePromoCkValue();
		
		console.log('ckvremove');
		
		cookie.remove('orderPaymentMode');
		cookie.remove('orderTableNo');
		cookie.remove('product_remarks');
		cookie.remove('orderOutletName');    
		cookie.remove('carttotalitems');
		cookie.remove('cartsubtotal');
		cookie.remove('cartid');
		cookie.remove('firstNavigation');
		
		/* Delivery avilablity */
		cookie.remove('orderDateTime');
		cookie.remove('deliveryDate');
		cookie.remove('deliveryTime');
		cookie.remove('unitNoOne');
		cookie.remove('unitNoTwo');

		cookie.remove('promotion_id');
		cookie.remove('promotion_applied');
		cookie.remove('promotion_code');
		cookie.remove('promotion_delivery_charge_applied');
		cookie.remove('promotion_amount');
		cookie.remove('promotion_category');
		cookie.remove('prmo_type');
		
		/*Remove voucher*/
		cookie.remove('voucher_applied');
		cookie.remove('voucher_code');
		cookie.remove('voucher_amount');
			
		cookie.remove('points_redeemed');
		cookie.remove('points_used');
		cookie.remove('points_amount');
		cookie.remove('prmo_type');
    }
	
	/* find Zone*/
    findOutletBasedZone(first, availability) {

		if (first) {
		  var postalcode = $("#postalcode").val();
		} else {
		  var postalcode = $("#postalcode1").val();
		}
		
		var outletIdTxt = this.state.delivery_outlet_id;

		if (outletIdTxt === '') {
		  $(".postal_error").html('<span class="error">Go Back and Select your delivery outlet.</span>');
		  return false;
		}
		
		if (postalcode.length < 5) {
		  $(".postal_error").html('<span class="error">Please enter valid postal code.</span>');
		  return false;
		}
		
		showLoader('delivery_submit_cls','class'); 
		
		
		axios.all([ 
		axios.get(apiUrlV2+'outlets/findOutletZone?app_id='+appId+"&availability_id="+availability+"&postal_code="+postalcode+"&outlet_id="+outletIdTxt),
	    axios.get(apiUrlV2+'settings/chkTimeslotIsAvaiable?app_id='+appId+"&availability_id="+availability+"&outletId="+outletIdTxt),
			]).then(axios.spread((res,timeslt) => { 
			       
					hideLoader('delivery_submit_cls','class');
					
					var deliveryInfo = [];
					/* Success response */
					if (res.data.status === "ok") {
					  $.magnificPopup.close();

					  cookie.save('outletchosen', availability);

					  var orderDeliveryAddress = res.data.result_set.postal_code_information.zip_buno + " " + res.data.result_set.postal_code_information.zip_sname;
					  var orderHandled = stripslashes(res.data.result_set.outlet_name) + ", Crew will be seeing you in " + res.data.result_set.outlet_delivery_timing + " Minutes";
					  
					  deliveryInfo['orderZoneId'] = res.data.result_set.zone_id;
					  deliveryInfo['orderOutletId'] = res.data.result_set.outlet_id;
					  deliveryInfo['orderOutletName'] = stripslashes(res.data.result_set.outlet_name);
					  deliveryInfo['orderPostalCode'] = res.data.result_set.postal_code_information.zip_code;
					  deliveryInfo['orderTAT'] = res.data.result_set.outlet_delivery_timing;
					  deliveryInfo['orderDeliveryAddress'] = orderDeliveryAddress;
					  deliveryInfo['orderHandled'] = orderHandled;
					  deliveryInfo['defaultAvilablityId'] = availability;
					  

					  var unitNum = this.showUnitNum(res.data.result_set.outlet_unit_number1,res.data.result_set.outlet_unit_number2);	
					

					  var orderHandledText = res.data.result_set.outlet_address_line1 + " " + res.data.result_set.outlet_address_line2 + ", "+unitNum+" Singapore " + postalcode;
					  
					  deliveryInfo['orderHandledByText'] = orderHandledText;
					  
					  this.setState({deliveryInfo: deliveryInfo,seletedAvilablityId: availability, seletedOutletId: res.data.result_set.outlet_id, order_tat_time: res.data.result_set.outlet_delivery_timing, orderHandled: orderHandled, orderDeliveryAddress: orderDeliveryAddress + " Singapore " + res.data.result_set.postal_code_information.zip_code });

					  /* Success time slot response */
					  if (timeslt.data.status === "success") {
							this.setState({getDateTimeFlg: 'yes'});

							removeOrderDateTime();
							removePromoCkValue();
							  
							$.magnificPopup.open({
								items: {
								  src: '#awesome-popup'
								},
								type: 'inline'
							});
					  } else {
						   $.magnificPopup.open({
								items: {
								  src: '#outlet-error-popup'
								},
								type: 'inline'
						   });
					  }
					  
					}

					/* Error response */
					if (res.data.status === "error") {
					
					  this.setState({deliveryInfo: deliveryInfo}); 			
						
					  $.magnificPopup.close();
					  $.magnificPopup.open({
						items: {
						  src: '#error-postal-popup'
						},
						type: 'inline'
					  });
					  
					  if (first === 0) {
						var mgsTxt = (res.data.message !== '') ? res.data.message : 'Please enter valid postal code.';  
						$(".postal_error").html('<span class="error">'+mgsTxt+'</span>');
					  }
					  
					}
						  		 
			 }));
		

	}

	/* Select outlet */
    selectOutlet(first, availability) {

		if (first) {
		  var postalcode = $("#postalcode").val();
		} else {
		  var postalcode = $("#postalcode1").val();
		}

		if (postalcode.length < 5) {
		  $(".postal_error").html('<span class="error">Please enter valid postal code.</span>');
		  return false;
		}
		
		showLoader('delivery_submit_cls','class'); 
		
		axios.get(apiUrl + 'outlets/find_outlet?skip_timing=Yes&app_id=' + appId + "&availability_id=" + availability + "&postal_code=" + postalcode)
		  .then(res => {
			
			hideLoader('delivery_submit_cls','class');
			
			/* Success response */
			if (res.data.status === "ok") {
			  $.magnificPopup.close();

			  cookie.save('outletchosen', availability);

			  var orderDeliveryAddress = res.data.result_set.postal_code_information.zip_buno + " " + res.data.result_set.postal_code_information.zip_sname;
			  var orderHandled = stripslashes(res.data.result_set.outlet_name) + ", Crew will be seeing you in " + res.data.result_set.outlet_delivery_timing + " Minutes";
			  this.setState({ orderDeliveryAddress: orderDeliveryAddress + " Singapore " + res.data.result_set.postal_code_information.zip_code });
			  this.setState({ orderHandled: orderHandled });
			  
			  cookie.save('orderOutletId', res.data.result_set.outlet_id);
			  cookie.save('orderOutletName', stripslashes(res.data.result_set.outlet_name));
			  cookie.save('orderPostalCode', res.data.result_set.postal_code_information.zip_code);
			  cookie.save('orderTAT', res.data.result_set.outlet_delivery_timing);
			  cookie.save('orderDeliveryAddress', orderDeliveryAddress);
			  cookie.save('orderHandled', orderHandled);
			  cookie.save('defaultAvilablityId', availability);

			  var orderHandledText = res.data.result_set.outlet_address_line1 + " " + res.data.result_set.outlet_address_line2 + ", Singapore " + postalcode;
			  cookie.save('orderHandledByText', orderHandledText);

			  removeOrderDateTime();
			  removePromoCkValue();

			  $.magnificPopup.open({
				items: {
				  src: '#awesome-popup'
				},
				type: 'inline'
			  });
			  
			}

			/* Error response */
			if (res.data.status === "error") {
			  $.magnificPopup.close();
			  $.magnificPopup.open({
				items: {
				  src: '#error-postal-popup'
				},
				type: 'inline'
			  });
			  
			  if (first === 0) {
				var mgsTxt = (res.data.message !== '') ? res.data.message : 'Please enter valid postal code.';  
				$(".postal_error").html('<span class="error">'+mgsTxt+'</span>');
			  }
			  
			}
			

		  });

	}
	
	
	gotoProducts() {
		if((cookie.load('orderOutletId'))== undefined || (cookie.load('orderOutletId')) == '' ){
			$(".outlet_error").html('<span class="error"> Please choose one outlet.</span>');		
		}else{
		  cookie.save('outletchosen', cookie.load('defaultAvilablityId'));
		  $.magnificPopup.close();
		  if(cookie.load('popuptriggerFrom') === 'FeaturedPro') {
			  cookie.remove('popuptriggerFrom');
			  this.props.history.push('/');
		  } else {
			  this.props.history.push('/products');
		  }
		}
    }
	
	selectDatetm() {
		var seletedOutletId = this.state.seletedOutletId;
		var pickupInfo = this.state.pickupInfo;
		if((seletedOutletId !== '') && (Object.keys(pickupInfo).length > 0)){
			showLoader('takeaway-btn-act','class');
			
			axios.get(apiUrlV2 + 'settings/chkTimeslotIsAvaiable?app_id=' + appId + "&availability_id=" + pickupId + "&outletId=" + seletedOutletId)
			  .then(res => {
				  
				  hideLoader('takeaway-btn-act','class');
				  
				  /* Success response */
				  if (res.data.status === "success") {
					    this.setState({getDateTimeFlg: 'yes'});

						removeOrderDateTime();
						removePromoCkValue();
						  
						$.magnificPopup.open({
							items: {
							  src: '#awesome-popup'
							},
							type: 'inline'
						});
				  } else {
					   $.magnificPopup.open({
							items: {
							  src: '#outlet-error-popup'
							},
							type: 'inline'
					   });
				  }
			});	  
			
		} else {
			$(".outlet_error").html('<span class="error"> Please choose one outlet.</span>');
		}
	}
	
	selectDlyOutlet() {
		if(this.state.delivery_outlet_id === '' ){
		 $('.delivery_outletpoup').find(".outlet_error").html('<span class="error"> Please choose one outlet.</span>');
		} else {
		  $.magnificPopup.close();
		  $.magnificPopup.open({
				items: {
				  src: '#delevery-postcode-popup'
				},
				type: 'inline'
		  });
		}
    }
	
	handleKeyPress = (event) => {
    
		var value = event.target.value.toLowerCase(),         
		 matches = this.state.pickupOutletsList.filter(function (item) {
			return item.outlet_address_line1.substring(0, value.length).toLowerCase() === value || item.outlet_postal_code.substring(0, value.length).toLowerCase() === value || stripslashes(item.outlet_name).substring(0, value.length).toLowerCase() === value;
		});
		
		this.setState({pickupOutlets:matches});
		
	}
	
	/* load outlets  */
	loadOutletsList() {
		if (Object.keys(this.state.pickupOutlets).length > 0) {
		  return this.state.pickupOutlets.map((loaddata, index) =>
		  <li key={index} className={this.activeOutlet(loaddata.outlet_id)} ><a href="/" onClick={this.pickOutlet.bind(this,loaddata)}>{stripslashes(loaddata.outlet_name)}, {loaddata.outlet_address_line1}, {this.showUnitNum(loaddata.outlet_unit_number1,loaddata.outlet_unit_number2)}, Singapore {loaddata.outlet_postal_code}</a></li>
			
		  );
		}else{
		  return <li   ><a>No Outlet found</a></li>
		}
	}
	
	activeOutlet(outletID) {
		var seletedOutletId = this.state.seletedOutletId;
		var pickupInfo = this.state.pickupInfo;
		var actTxt = ((parseInt(seletedOutletId)===parseInt(outletID)) && (Object.keys(pickupInfo).length > 0)) ? "active" : '';
		return actTxt;
	}
	
	
	handleKeyPressDly = (event) => {
    
		var value = event.target.value.toLowerCase(),         
		 matches = this.state.deliveryOutletsList.filter(function (item) {
			return item.outlet_address_line1.substring(0, value.length).toLowerCase() === value || item.outlet_postal_code.substring(0, value.length).toLowerCase() === value || stripslashes(item.outlet_name).substring(0, value.length).toLowerCase() === value;
		});
		
		this.setState({deliveryOutlets:matches});
		
	}

	showUnitNum(unit1,unit2) {
		unit1 = (typeof unit1 !== 'undefined') ? unit1 : '';
		unit2 = (typeof unit2 !== 'undefined') ? unit2 : '';
		
		if(unit1 !== '') {
			var unitNo = (unit2 !== '') ? unit1+' - '+unit2 : unit1;
		} else {
			var unitNo = unit2;
		}
		
		return (unitNo !== '')?'#'+unitNo:'';
	}	

	/* load delivery outlets  */
	loadDeliveryOutletsList() {
		if (Object.keys(this.state.deliveryOutlets).length > 0) {
		  return this.state.deliveryOutlets.map((loaddata, index) =>
		  <li key={index} className={this.activeDlyOutlet(loaddata.outlet_id)} ><a href="/" onClick={this.deliveryOutlet.bind(this,loaddata)}>{stripslashes(loaddata.outlet_name)}, {loaddata.outlet_address_line1}, {this.showUnitNum(loaddata.outlet_unit_number1,loaddata.outlet_unit_number2)}, Singapore {loaddata.outlet_postal_code}</a></li>
			
		  );
		}else{
		  return <li   ><a>No Outlet found</a></li>
		}
	}
	
	
	activeDlyOutlet(outletID) {
		
		var orderOutletId = (this.state.delivery_outlet_id !== '') ? this.state.delivery_outlet_id :cookie.load('orderOutletId');
		
		return (orderOutletId === outletID?"active":'');
	}
	
	/* pick outlet */
	pickOutlet(loaddata, event){

		  event.preventDefault();	
		  var unitNum = this.showUnitNum(loaddata.outlet_unit_number1,loaddata.outlet_unit_number2);	
			
		  var orderHandled = stripslashes(loaddata.outlet_name)+" "+loaddata.outlet_address_line1+" "+loaddata.outlet_address_line2+", "+unitNum+" Singapore "+loaddata.outlet_postal_code;
		
		  var pickupInfo = [];
			  pickupInfo['orderOutletId'] = loaddata.outlet_id;
			  pickupInfo['orderOutletName'] = stripslashes(loaddata.outlet_name);
			  pickupInfo['orderPostalCode'] = loaddata.outlet_postal_code;
			  pickupInfo['orderTAT'] = loaddata.outlet_pickup_tat;
			  pickupInfo['orderHandled'] = orderHandled;
			  pickupInfo['orderHandledByText'] = orderHandled;
			  pickupInfo['defaultAvilablityId'] = pickupId;	
		
		  this.setState({pickupInfo: pickupInfo, seletedAvilablityId: pickupId, seletedOutletId: loaddata.outlet_id,  order_tat_time: loaddata.outlet_pickup_tat, orderHandled:orderHandled}, function() { this.selectDatetm(); }.bind(this));
	}
	
	deliveryOutlet(loaddata, event){
		event.preventDefault();
		$('.delivery_outletpoup').find(".outlet_error").html('');
		this.setState({delivery_outlet_id:loaddata.outlet_id}, function () { this.selectDlyOutlet(); }.bind(this));
	}
	
	trgContinuBtn(idTxt) {
		$("#"+idTxt).trigger("click");
	}
	
	componentDidMount() {
		
		$(".custom_close").click(function (e) {
			e.preventDefault();
			$('.custom_alertcls, .custom_center_alertcls').hide();
		});

		$('.test-popup-link').magnificPopup({
		  type: 'image',
                  showCloseBtn:true,
                  verticalFit: true,
		   callbacks: {
            		 change: function() {
			  this.wrap.addClass('awardpopup');
		       	 },
         		}
		  // other options
		});

		this.props.getMenuData('copper-header-menu');
		if ($(".open-popup-link").length > 0) {
		  $('.open-popup-link').magnificPopup({
			type: 'inline',
			midClick: true 
		  });
		}
		
		if ($(".trigger_login").length > 0) {
			$('.trigger_login').magnificPopup({
				type: 'inline',
				midClick: true 
			});
        }
		
		/* Input lable animation */
		if ($(".input-focus").length > 0) {
			$('.input-focus').focus(function() {
				$(this).parents('.focus-out').addClass('focused');
			});
			$('.input-focus').blur(function() {
				var inputValue = $(this).val();
				if (inputValue == "") {
					$(this).removeClass('filled');
					$(this).parents('.focus-out').removeClass('focused');
				} else {
					$(this).addClass('filled');
				}
			});
		}
			  
	    if ($(".hsearch_trigger").length > 0) {
		  $('.hsearch_trigger').click(function (e) {
			e.preventDefault();
			e.stopPropagation();
			$('.search_result').hide();
			$(this).toggleClass('active');
			$('.hsearch_sec').toggleClass('open');
			$('.hsearch_sec .form-control').focus();
		  });
		  $(document).click(function (e) {
			if (!$(e.target).is('.hsearch_trigger, .hsearch_sec, .hsearch_sec * ')) {
			  if ($('.hsearch_sec').is(":visible")) {
				$('.hsearch_sec').removeClass('open');
				$('.hsearch_trigger').removeClass('active');
				$('.hsearch_sec .form-control').blur();
			  }
			}
		  });
		}
		
		if ($(".hsearch_trigger_mbl").length > 0) {
		  $('.hsearch_trigger_mbl').click(function (e) {
			e.preventDefault();
			e.stopPropagation();
			$('.search_result_mbl').hide();
			$(this).toggleClass('active');
			$('.hsearch_sec_mbl').toggleClass('open');
			$('.hsearch_sec_mbl .form-control').focus();
		  });
		  $(document).click(function (e) {
			if (!$(e.target).is('.hsearch_trigger_mbl, .hsearch_sec_mbl, .hsearch_sec_mbl * ')) {
			  if ($('.hsearch_sec_mbl').is(":visible")) {
				$('.hsearch_sec_mbl').removeClass('open');
				$('.hsearch_trigger_mbl').removeClass('active');
				$('.hsearch_sec_mbl .form-control').blur();
			  }
			}
		  });
		}
		
		if ($(".mobile_mainacc_menutrigger").length > 0) {
            $(".mobile_mainacc_menutrigger").click(function(e){
                e.stopPropagation();            
                if ($('.mobile_mainacc_menulist').is(":visible")) {
                    $('.mobile_mainacc_menulist').hide();
                }
                else{
                    $('.mobile_mainacc_menulist').show();            
                }
            });
            $(document).click(function(e) {
                if (!$(e.target).is('.mobile_account_item, .mobile_account_item * ')) {
                    if ($('.mobile_mainacc_menulist').is(":visible")) {
                        $('.mobile_mainacc_menulist').hide();
                    }
                }
            });
        }
		
		/*NEW MENU SECTION*/
        if ($(".mobile_account_menu_click").length > 0) {
            $(".mobile_account_menu_click").click(function (e) {
				e.preventDefault();
                e.stopPropagation();
                $('.mobile_account_menu').slideToggle();
            });
            $(document).click(function (e) {
                if (!$(e.target).is('.arrow_myaccount, .mobile_account_menu_col')) {
                    if ($('.mobile_account_menu').is(":visible")) {
                        $('.mobile_account_menu').slideToggle();
                    }
                }
            });
        }
		
		/* Mobile header menu */
        $('.hcategory_trigger').click(function () {
            $(this).toggleClass('active');
            $('.hcategory_menu').toggleClass('open');
        });

        $(document).click(function (e) {
            if (!$(e.target).is('.hcategory_trigger')) {
                if ($('.hcategory_menu').hasClass("open")) {
                    $('.hcategory_menu').removeClass('open');
                    $('.hcategory_trigger').removeClass('active');
                }
            }
        });
		
	    $('.disbl_href_action').click(function (e) {
		  e.preventDefault();
	    });
		
		
		/*this.getOptions.bind(this,"mill");*/
		this.getSearchProductList();
	}
	
	componentDidUpdate() {
		
	}
	
	
	getSearchProductList() {
		
		var orderOutletIdtext = cookie.load('orderOutletId');
		var addquery_txt = '';
		if (typeof orderOutletIdtext !== "undefined" && orderOutletIdtext !== '') {
		  addquery_txt = "&outletId=" + orderOutletIdtext;
		}
		var searchResult = [];
		return axios.get(apiUrlV2 + 'products/search_products?app_id=' + appId + "&status=A&availability=" + cookie.load('defaultAvilablityId') + addquery_txt).then((response) => {
		  if (response.data.status === "ok") {
			var resultData = response.data.result_set;
			    for (var key in resultData) {
					var subcatListArr = (!('product_list' in resultData[key])) ? Array() : resultData[key].product_list;
					
					if(Object.keys(subcatListArr).length > 0) {
					  if(Object.keys(subcatListArr[0]).length > 0) {
						var subCatArr = (!('subcategorie' in subcatListArr[0][0])) ? Array() : subcatListArr[0][0].subcategorie;
						for (var sctkey in subCatArr) {
							var productsArr = (!('products' in subCatArr[sctkey])) ? Array() : subCatArr[sctkey].products;
							for (var prokey in productsArr) {
								var proNameTxt = getAliasName(productsArr[prokey].product_alias, productsArr[prokey].product_name);
								searchResult.push({ cate_slug: subCatArr[sctkey].pro_cate_slug, subcate_slug: subCatArr[sctkey].pro_subcate_slug, value: productsArr[prokey].product_slug, label: stripslashes(proNameTxt)})
							}
						}
					  }
					}
				}
				
		  }
		  
		  this.setState({searchProResult: searchResult, selectedProResult: searchResult});
		});
		
	}
	
	searchProKeyPress = (event) => {
    
		$('.search_result').show();
		$('.search_result_mbl').show();
		
		var value = event.target.value.toLowerCase(),         
		 matches = this.state.searchProResult.filter(function (item) {
			return item.label.substring(0, value.length).toLowerCase() === value;
		});
		
		$('#clearSearch').show();
		if(value === '') {
			$('#clearSearch').hide();
		}
		
		this.setState({selectedProResult:matches});
		
	}
	
	/* load product search result  */
	loadProSearchList() {
		if (Object.keys(this.state.selectedProResult).length > 0) {
		  return this.state.selectedProResult.map((loaddata, index) =>
		  <li key={index}><Link to={"/products/"+loaddata.cate_slug+"/"+loaddata.subcate_slug+"/"+loaddata.value} title="Product Details" >{loaddata.label}</Link></li>
			
		  );
		}else{
		  return <li className="no-pro-found">No Product found</li>
		}
	}
	
	clearSearchFun() {
		 var emtytxt = '';
		 $('#productsearch').val(emtytxt);
		 this.setState({selectedProResult:this.state.searchProResult});
	}
	
	getOptions = (input) => {
		
		var searchProResult = this.state.searchProResult;
		var searchResult = [];
		/*if (Object.keys(searchProResult).length > 0) {*/
			 /*searchProResult.map((loadData) =>
			  searchResult.push({ value: loadData.value, label: loadData.label })
			 );*/
			 searchResult.push({ value: 'wqewrr', label: 'fish cury' });
			 searchResult.push({ value: 'werew3', label: 'fish cury2' });
			 console.log('wlll');
		     console.log(searchResult);
		     console.log('input');
		     console.log(input);
			 return { options: searchResult };
		/*}*/
    };
	
	ViewProducts(event) {
		var productSlug = event.value;
		this.props.history.push("/products/cat-ftrpro/slug-ftrpro/" + productSlug);
    }
	
	checkAblBtn() {
		
		  var availability = cookie.load('defaultAvilablityId');
		  var orderOutletId = cookie.load('orderOutletId');
		  var actText = "Order Now";
		  if(availability === deliveryId && orderOutletId !== '' && orderOutletId !== undefined){
			  actText = "Delivery";
		  } else if(availability === pickupId && orderOutletId !== '' && orderOutletId !== undefined){
			  actText = "Takeaway";
		  } else if(availability === cateringId){
			  actText = "Catering";
		  } else if(availability === reservationId){
			  actText = "Reservation";
		  }
		  
		  return (<a href="/" onClick={this.closepopup.bind(this)} className="hordertype_btn" title={actText}>{actText}</a>);
	  }
	  
	  checkActiveDivHd(avlType) {
		  var clsTxt = "ordericon_link ";
		  var availability = cookie.load('defaultAvilablityId');
		  var orderOutletId = cookie.load('orderOutletId');
		  if(availability==avlType && orderOutletId !== '' && orderOutletId !== undefined){
			  clsTxt += 'active';
		  } else if(availability==avlType && (avlType === cateringId || avlType === reservationId)){
			  clsTxt += 'active';
		  } 
		  return clsTxt; 
	  }
	  
	  checkActiveDivMbl(avlType) {
		  var clsTxt = "";
		  var availability = cookie.load('defaultAvilablityId');
		  var orderOutletId = cookie.load('orderOutletId');
		  if(availability==avlType && orderOutletId !== '' && orderOutletId !== undefined){
			  clsTxt += 'active';
		  } else if(availability==avlType && (avlType === cateringId || avlType === reservationId)){
			  clsTxt += 'active';
		  } 
		  return clsTxt; 
	  }
	  
	  myAccountAction() {
		  
		  var currenturl = window.location.href;
          var substringtxt = "myaccount";
		  
		  if(currenturl.includes(substringtxt) !== true) {
			  return (<div><Link to={"/myaccount"} className="myacunt_act" title="My Account"> My Account </Link><span className="mobile_mainacc_menutrigger"></span></div>)
		  } else {
			  return (<div><a className="myacunt_act disbl_href_action" href="/"> My Account </a><span className="mobile_mainacc_menutrigger"></span></div>)
		  }
	  }
	  
	  changPostalValue(type,pstVl) {
		  if(type === 1) {
			$("#postalcode").val(pstVl);
		  } else {
			$("#postalcode1").val(pstVl); 
		  }
	  }
	  
	  userAddressList(typeTxt) {
		  if (this.state.secondaryaddresslist.length > 0) {
				var addListTxt = this.state.secondaryaddresslist.map((addr, index) =>
				    <div className="address_linfo" key={typeTxt+'-'+index}>
					   <div className="custom_radio">
						<input type="radio" name={"address_chk"+typeTxt} value={addr.postal_code} className="address_chk" onChange={this.changPostalValue.bind(this, typeTxt, addr.postal_code)} />
						<span>{(addressFormat(addr.unit_code, addr.unit_code2, addr.address, addr.country, addr.postal_code))}</span>
						</div>		
					</div>);
					
				  return (<div>
						   {addListTxt}
						   <div className="address_linfo" key={typeTxt+'dfl'}>
							   <div className="custom_radio">
								<input type="radio" name={"address_chk"+typeTxt} value='' defaultChecked={true} className="address_chk" onChange={this.changPostalValue.bind(this, typeTxt, '')} />
								<span>Enter New Address</span>
								</div>		
							</div> 	
						  </div>);	
					
			} else {
				return ''
			}
		  
	  }
	  
	  setdateTimeFlg = (field, value) => {
		  if(field == 'tmflg') {
				this.setState({getDateTimeFlg:value});
		  }	
		  else if(field == 'ordDate') {
				var ordTime = '';
				$('.ordrdatetime_error').html('');
				this.setState({seleted_ord_date:value,seleted_ord_time:ordTime});
		  }		
		  else if(field == 'ordTime') {
			    $('.ordrdatetime_error').html('');
				this.setState({seleted_ord_time:value});
		  }
		  else if(field == 'triggerErrorPopup') {
			    $.magnificPopup.open({
					items: {
					  src: '#outlet-error-popup'
					},
					type: 'inline'
			    });
		  }	  
	  }
	  
	  setOrderOutletDateTimeData() {
		
		var seletedOrdDate = this.state.seleted_ord_date;
		var seletedOrdTime = this.state.seleted_ord_time;
		if(seletedOrdDate !== '' && seletedOrdTime !== '' && seletedOrdDate !== null && seletedOrdTime !== null) {
			var orderInfoData = (this.state.seletedAvilablityId === pickupId) ? this.state.pickupInfo : this.state.deliveryInfo;
			if(Object.keys(orderInfoData).length > 0) {
				
				var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
				var OrderHours = seletedOrdTime.getHours();
				var OrderMunts = seletedOrdTime.getMinutes();
				var OrderSecnd = seletedOrdTime.getSeconds();
				var orderDateTime = new Date(OrderDate);
					  orderDateTime.setHours(OrderHours);
					  orderDateTime.setMinutes(OrderMunts);
					  orderDateTime.setSeconds(OrderSecnd);
			
                var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
				var deliveryTime = this.convPad(OrderHours)+":"+this.convPad(OrderMunts)+":"+this.convPad(OrderSecnd);			
				
				cookie.save('orderDateTime', orderDateTime);
				cookie.save('deliveryDate', deliveryDate);
				cookie.save('deliveryTime', deliveryTime);
				
				
				if(this.state.seletedAvilablityId === deliveryId) {
					cookie.save('orderZoneId', orderInfoData['orderZoneId']);
					cookie.save('orderDeliveryAddress', orderInfoData['orderDeliveryAddress']);
				}
				
				cookie.save('orderOutletId', orderInfoData['orderOutletId']);
			    cookie.save('orderOutletName', orderInfoData['orderOutletName']);
			    cookie.save('orderPostalCode', orderInfoData['orderPostalCode']);
				cookie.save('orderTAT', orderInfoData['orderTAT']);
				cookie.save('orderHandled', orderInfoData['orderHandled']);
				cookie.save('defaultAvilablityId', orderInfoData['defaultAvilablityId']);
				cookie.save('orderHandledByText', orderInfoData['orderHandledByText']);
				
				cookie.save('outletchosen', orderInfoData['defaultAvilablityId']);
			    $.magnificPopup.close();
			    if(cookie.load('popuptriggerFrom') === 'FeaturedPro') {
				   cookie.remove('popuptriggerFrom');
				   this.props.history.push('/');
			    } else {
				   this.props.history.push('/products');
			    }
				
			} else {
				$.magnificPopup.open({
					items: {
					  src: '#outlet-error-popup'
					},
					type: 'inline'
			    });
			}
			
		} else {
			$(".ordrdatetime_error").html('<span class="error"> Please select order date and time.</span>');
		}
		
	}
	
	convPad(d) {
		return (d < 10) ? '0' + d.toString() : d.toString();
    }
	
	searBlkFun(typeTxt) {
		
		var defaultAvilTyId = cookie.load('defaultAvilablityId');
		var liTopCls = 'htico_search';
		var scearDivTopCls = 'hsearch_sec';
		var triggerActCls = 'hsearch_trigger';
		var searchResultCls = 'search_result';
		var searchIconcls = 'search_i_icon';
		if(typeTxt === 'mbl') {
			liTopCls = 'htico_search_mbl';
			scearDivTopCls = 'hsearch_sec_mbl';
			triggerActCls = 'hsearch_trigger_mbl';
			searchResultCls = 'search_result_mbl';
			searchIconcls = 'fa fa-search';
		}
		
		return(<li className={liTopCls}>
						
				{(defaultAvilTyId === cateringId || defaultAvilTyId === reservationId) && <a href="/" data-effect="mfp-zoom-in" className="hsearch_trigger_dis disbl_href_action" title="Search">&nbsp;</a>}
			
				{(cookie.load('orderOutletId') !== undefined && defaultAvilTyId !== cateringId && defaultAvilTyId !== reservationId) && <a href="/" className={triggerActCls} title="Search"><i className={searchIconcls}></i></a>}
				
				{(cookie.load('orderOutletId') === undefined && defaultAvilTyId !== cateringId && defaultAvilTyId !== reservationId) && <a href="#order-popup" data-effect="mfp-zoom-in" className={triggerActCls+" open-popup-link"} title="Search"><i className={searchIconcls}></i></a>}
				
				<div className={scearDivTopCls}>
				
					<div className="input-sec">
					<input type="text" name="searchkey" id="productsearch" placeholder="Search..." onKeyUp={this.searchProKeyPress} />
					
					 <a href="/" id="clearSearch" onClick={this.clearSearchFun.bind(this)} className="search_text_clear disbl_href_action">
						 X
					 </a>
					</div>
					
					<ul className={searchResultCls} id={searchResultCls}>
					{this.loadProSearchList()}
					</ul>
					
				</div>
				
			</li>);
	}
	
    /* ViewProducts */

    render() {
		
		var currenturl = window.location.href;
        var substring = "products";
		var isCheckout = "checkout";
		
		var showCatryName = (typeof this.props.showCatryName !== "undefined") ? this.props.showCatryName : 'Category Name';
		
		var defaultAvilTyId = cookie.load('defaultAvilablityId');
		
        return (
			   <header>
			   
				  <div className="header-top-cls">
					  <div className="container">
						<div className="logo-main-section">
						
							<div className="menu_icon trigger_menunav_act">
								<span className="icon-bar icon-bar1"></span>
								<span className="icon-bar icon-bar2"></span>
								<span className="icon-bar icon-bar3"></span>
							</div>
							<div className="mobile-menu">
								<div className="mobile-menu-header">
									<div className="mobile-menu-close"><span id="close_mobile_menu"></span></div>
									<div className="mobile-menu-logo">
										<Link to={"/"} title="CopperChimney">
											<img src={mainLogo} alt="Logo"/>
										</Link>
									</div>
								</div>
								<div className="mobile-menu-body">
								   <ul className="menu">
									{this.state.menudata && this.listMainNavigation()}	
								   </ul>	
								</div>
							</div>
							
							
							<div className="logo">
								<Link to={"/"} title="CopperChimney">
									<img src={mainLogo} alt="Logo"/>
								</Link>
							</div>
						
						   <div className="hmenu-login-section">
							<ul className="hmenu-login-act">
							{!cookie.load("UserId") && <li className="hsign_sec">
							<a href="#login-popup" data-effect="mfp-zoom-in" className="open-popup-link htico_sign" title="Login"><span>Login</span></a>
							<span>|</span>
							<a href="#signup-popup" data-effect="mfp-zoom-in" className="open-popup-link" title="Sign up"> <span>Register</span></a>
							</li>}
							
							{cookie.load("UserId") &&  <li className="hsign_sec">
								<Link className="htico_sign" to="/myaccount" title="My Account"><i /> <span>My Account</span></Link><span>|</span>
								<Link className="htico_sign" to="/logout" title="Logout"><i /> <span>Logout</span></Link>
							</li>}
							
							{this.searBlkFun('mbl')}
							
							<CartSideBar {...this.props} headerState={this.state} prpSateValChange={this.props.sateValChange} />
							
							</ul>
						   </div>
							
						</div>
					  </div>	
				  </div>		
			   
					
				{/* header-bottom - start */}
				<div className="header-bottom">
				  <div className="container">
				    <div className="header-bottom-innersec">
					
					  <div className="haction_sec">
						<div className="header_bot">
								
								<div className="hmenu_sec">
								
								  <ul className="hmenu_list desktop_hmenu_list">
									{this.state.menudata && this.listMainNavigation()}
								  </ul>
								 
							   </div>
							
						  </div>
					   </div>
					
					
					  <ul className="hmenu_actions">
						
						{this.searBlkFun('desktop')}
						
						<li className="hordertype_sec">
						{this.checkAblBtn()}
						</li>
						
					  </ul>
					
				   </div>
				 </div>
				</div>
				{/* header-bottom - end */}	
				 
				
				{/* mobile_accountdel_body - start */}
				<div className="mobile_accountdel_body mobile-order-nowbtn">
					<div className="mobile_accountdel_row">
					
						<div className="mobile_accountdel_col">

							{!cookie.load("UserId") && <div className="mobile_account_item"><div className="mobile_account_item_login"><a className="open-popup-link" href="#login-popup" data-effect="mfp-zoom-in"  ><i></i>Login</a> <span className="myaccount_separate"> </span>
								<a href="#signup-popup" data-effect="mfp-zoom-in" className="open-popup-link signup_btn"  ><i></i>Signup</a> </div> </div>}
								
							{cookie.load("UserId") && <div className="mobile_account_item">
							
							{this.myAccountAction()}
							
							<div className="mobile_mainacc_menulist">
								<ul>
									<li><a href="#change-password-popup" className="open-popup-link">Change Password</a></li>
									<li><Link to={"/logout"} title="Logout">Logout</Link></li>
								</ul>
							</div>
							
							</div>}

						</div>
						
						<div className="mobile_accountdel_col">
							<a className="mobile_account_menu_click" href="/">{this.chooseAvailabilityMbl()} <i className="arrow_myaccount"></i></a>
							
							<div className="mobile_account_menu">
								<ul className="mobile_account_delivery_item">
									<li className={this.checkActiveDivMbl(deliveryId)}>
									<a href="/" onClick={this.chooseAvailabilityFun.bind(this, deliveryId)} className="open-popup-link-dis" >Delivery</a></li>
									<li className={this.checkActiveDivMbl(pickupId)}>
									<a href="/" onClick={this.chooseAvailabilityFun.bind(this, pickupId)} data-effect="mfp-zoom-in" className="open-popup-link-dis">Takeaway</a></li>
									<li className={this.checkActiveDivMbl(cateringId)}>
									<a href="https://forms.gle/dUXhsLwrLexZReco6" target={'_blank'} data-effect="mfp-zoom-in" >Catering</a></li>
									<li className={this.checkActiveDivMbl(reservationId)}>
									<a href="https://bookv5.chope.co/booking?rid=copperchimney1806cpc&source=rest_www.copperchimney.com.sg&select_location=1" target={'_blank'} data-effect="mfp-zoom-in" >Reservation</a></li>
									{/*<li className={this.checkActiveDivMbl(cateringId)}>
									<a href="/" onClick={this.chooseAvailabilityFun.bind(this, cateringId)} data-effect="mfp-zoom-in" className="open-popup-link-dis">Catering</a></li>
									<li className={this.checkActiveDivMbl(reservationId)}>
									<a href="/" onClick={this.chooseAvailabilityFun.bind(this, reservationId)} data-effect="mfp-zoom-in" className="open-popup-link-dis">Reservation</a></li>*/}
								</ul>
							</div>
						</div>
						
					</div>
				</div>
				{/* mobile_accountdel_body - end */}
				
				
				{currenturl.includes(substring) && <div className="hcategory_sec">
					<a href="/" className="hcategory_selected_text disbl_href_action">{showCatryName} <span className="hcategory_trigger">View More</span></a>
					<div className="hcategory_menu ">
						<MenuNavigationmob />
					</div>
				</div>}
				
				
				<div className="custom_alertcls alert-success alert-dismissible1 alert_fixed success_hide" id="jquery-success-msg" style={{display:'none'}}> <a href="/" type="button" className="custom_close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span> </a> <p className="jquery-success-msg">Nice! Products added to your cart</p> </div>
				
				<div className="custom_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide" id="jquery-error-msg" style={{display:'none'}}> <a href="/" className="custom_close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span> </a> <p className="jquery-error-msg">Something went wrong</p> </div>
				
				<div className="custom_center_alertcls alert-success alert-dismissible1 alert_fixed success_hide" id="jquery-common-success-msg" style={{display:'none'}}> <a href="/" type="button" className="custom_close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span> </a> <p className="jquery-common-success-msg">Nice! Products added to your cart</p> </div>
				
				<div className="custom_center_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide" id="jquery-common-error-msg" style={{display:'none'}}> <a href="/" className="custom_close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span> </a> <p className="jquery-common-error-msg">Something went wrong</p> </div>
				
				{/* login popup */}

				 <div id="login-popup" className="white-popup mfp-hide popup_sec login-popup">
				 <div className="pop-whole">
					<div className="pop-whole-lhs">
						 <div className="pop-whole-lhs-inner">
							 <h3>Have an account ?</h3>
							 <p>Log in with your details to manage.</p>
						 </div>
					 </div>
					 <div className="inside-popup-rhs">
						 <div className="popup-footer">
							 <FacebookLogin
									 appId={fbAppId}
									 fields="name,email,picture,first_name,last_name,birthday,gender"
									 callback={this.responseFacebook}
									 scope="public_profile,email,user_birthday"
									 cssclassName="btn btn-black  fa fa-facebook"
									 redirectUri={baseUrl}
									 icon="fa-facebook"
									 textButton="&nbsp; Login with Facebook"
							 />
							 
							 <div className="or-seperator"><span>Or</span></div>
						 </div>
						 <div className="popup-header textcenter">
							 <h4>Login with<img src={footerLogo} /></h4>
						 </div>
						  <Login fields={this.state.fields} onChange={this.fieldChange} onValid={this.handleSignin} onInvalid={() => console.log('Form invalid!')} />
						  
						  <div className="guest-checkout-popupdiv textcenter">
							<a href="#guest-popup" className="button open-popup-link">No I Want To Checkout As Guest</a>
						 </div>
						 
					 </div>
				 </div>
				</div>
				
			   {/* Signup popup */}
				<div id="signup-popup" className="white-popup mfp-hide popup_sec signup-popup">
				 <div className="pop-whole">
					<div className="pop-whole-lhs">
						{/* <div className="back_click_mbl">
							<a href="#login-popup" className="open-popup-link" >Back</a>
						</div> */}
						<div className="pop-whole-lhs-inner">
							<h3>New to Copper Chimney ?</h3>
							<p>Create your Copper Chimney account.</p>
						</div>
					</div>
					 <div className="inside-popup-rhs">
					    {/* <div className="back_click">
							<a href="#login-popup" className="open-popup-link" >Back</a>
						</div> */}
						 <div className="popup-footer signup-popup-head">
							 <FacebookLogin
									 appId={fbAppId}
									 fields="name,email,picture,first_name,last_name,birthday,gender"
									 callback={this.responseFacebook}
									 scope="public_profile,email,user_birthday"
									 cssclassName="btn btn-black  fa fa-facebook"
									 redirectUri={baseUrl}
									 icon="fa-facebook"
									 textButton="&nbsp; Login with Facebook"
							 />
							 
							 <div className="or-seperator"><span>Or</span></div>
						 </div>
						 <div className="popup-header textcenter">
							 <h4>Create account with <img src={footerLogo} /></h4>
						 </div>
						 <Signup fields={this.state.fieldssignup} onChange={this.fieldChangeSignup} onValid={this.handleSignup} onInvalid={() => console.log('Form invalid!')} />
					 </div>
				 </div>
				</div>
				
				
				{/* Guest Checkout */}
				<div id="guest-popup" className="white-popup mfp-hide popup_sec guest-popup">
					<div className="pop-whole">
						<div className="pop-whole-lhs">
						   <div className="back_click_mbl">
								<a href="#login-popup" className="open-popup-link" >Back</a>
							</div>
							<div className="pop-whole-lhs-inner">
								<h3>New to Copper Chimney ?</h3>
								<p>Create your Copper Chimney account.</p>
							</div>
						</div>
						<div className="inside-popup-rhs">
							<div className="back_click">
								<a href="#login-popup" className="open-popup-link" >Back</a>
							</div>
							<Guestcheckout fields={this.state.fieldscheckout} onChange={this.fieldChangecheckout} onValid={this.handleCheckout} onInvalid={() => console.log('Form invalid!')} />
						</div>
					</div>
				</div>
				

				{/* Forgot Password Popup */}

				<div id="forgot-password-popup" className="white-popup mfp-hide popup_sec login-popup forgot-password-popup">
				 <div className="pop-whole">
				 
				  <div className="pop-whole-lhs">
					 <div className="pop-whole-lhs-inner">
						 <h3>Forgot your password?</h3>
						 <p>You can reset your password here.</p>
					 </div>
				  </div>
				  
				  <div className="inside-popup-rhs">
					 <div className="popup-header textcenter">
						 <h4><img src={footerLogo} /></h4>
					 </div>	
					 <Forgotpassword fields={this.state.fieldsfgtpassword} onChange={this.fieldforgot} onValid={this.forgotpassword} onInvalid={() => console.log('Form invalid!')} />
				  </div>
				  
				 </div>
				</div>
				
				
				{/* Order popup - start */}
				  <div id="order-popup" className="white-popup mfp-hide popup_sec order_popup">
					<div className="order-body">
					  <h2>Order Now</h2>
					  <p>Select your order type</p>
					  <div className="order_delivery_row">
						<div className="order_delivery_col">
						  <ul className="order_delivery_item">
						  
							<li>
							  <a href="/" onClick={this.chooseAvailabilityFun.bind(this, deliveryId)} className={this.checkActiveDivHd(deliveryId)}>
								
								<div className="header-ordernow-single-img">
									<img className="order_type_img" src={deliveryImg} />
									<img className="order_type_imgwt" src={deliveryWtImg} />
									<h3>Delivery</h3>
								</div>
							  </a>
							</li>
							
							<li>
							  <a href="/" onClick={this.chooseAvailabilityFun.bind(this, pickupId)} className={this.checkActiveDivHd(pickupId)}>
								
								<div className="header-ordernow-single-img">
									<img className="order_type_img" src={takeawayImg} />
									<img className="order_type_imgwt" src={takeawayWtImg} />
									<h3>Takeaway</h3>
								</div>
							  </a>
							</li> 
							
							<li>
							{/*<a href="/" onClick={this.chooseAvailabilityFun.bind(this, cateringId)} className={this.checkActiveDivHd(cateringId)}>*/}
							  <a href="https://forms.gle/dUXhsLwrLexZReco6" target={'_blank'} className={this.checkActiveDivHd(cateringId)}>
								
								<div className="header-ordernow-single-img">
									<img className="order_type_img" src={cateringImg} />
									<img className="order_type_imgwt" src={cateringWtImg} />
									<h3>Catering</h3>
								</div>
							  </a>
							</li>
							
							{/*<li>
							  <a href="javascript:;" onClick={this.chooseAvailabilityFun.bind(this, reservationId)} className={this.checkActiveDivHd(reservationId)}>
								
								<div className="header-ordernow-single-img">
									<img src={reservationImg} />
									<h3>Reservation</h3>
								</div>
							  </a>
							</li>*/}
							
						  </ul>
						</div>
					  </div>
					</div>
				  </div>
				  {/* Order popup - end */}
				  
				   {/* Delivery Popup - Start */}
				  <div id="delevery-popup" className="white-popup mfp-hide popup_sec delivery_outletpoup self_popup">
					<div className="order-body">
					
					  <div className="self_popup_hea_row">
						<div className="self_popup_hea_col_left">
						  <img className="outlet-scooter-img" src={deliveryWtImg} />
						</div>
						<div className="self_popup_hea_col">
						  <h2>Please Select</h2>
						  <p>Your Delivery Outlet</p>
						</div>
					  </div>
					  
					  <div className="self_pop_row">
						<div className="self_pop_col self_pop_col_right">
						  <div className="self_pop_item">
						  
							<div className="self_pop_locbx"> 
								<div className="form-group">
								  <div className="focus-out">
									<label>Search Outlet</label>
									<input type="input" className="form-control input-focus" onKeyUp={this.handleKeyPressDly} />
									<div className="outlet_error"></div>
								  </div>
								</div>
							</div>
				
							<div className="self_outlet">
								<h2>Nearby Outlets</h2>
								<ul className="self_outlet_inner">
								{ this.loadDeliveryOutletsList() }
								</ul>
								<a className="button disbl_href_action" id="delivery-continue-link" href="/" onClick={this.selectDlyOutlet.bind(this)}>Continue</a>
							</div>
							
						  </div>
						</div>
					  </div>
					  
					</div>
				  </div>
				  {/* Delivery Popup - end */}
				  
				  {/*  Delivery Postal code Popup - start */}
				  <div id="delevery-postcode-popup" className="white-popup mfp-hide popup_sec delevery_popup">
					<div className="popup_equalrw">
					  <div className="popup_ttsec">
						<div className="innervmid_in">
						  <div className="pop_title">
							<img className="pop-scooter-img" src={deliveryWtImg} />
							<h2 className="text-uppercase">Let us know</h2>
							<small>Your Delivery Location</small>
						  </div>
						</div>
					  </div>
					  <div className="popup_right">
						<div className="innervmid_in">
							
							{cookie.load("UserId") &&  <div className="address-list-cls address-list-main">
							{this.userAddressList(1)}
						    </div>}
							
							<div className="form-group">
							  <div className={(this.state.secondaryaddresslist.length > 0)?"focus-out focused":"focus-out"}>
								<label>Enter your postal code</label>
								<input type="text" id="postalcode" pattern="\d*" maxLength="6"  className="form-control input-focus" />
								<div className="postal_error"></div>
							  </div>
							</div>
							<div className="btn_sec">
							  <div className="two-button-row">
								<div className="go_second">
								 {/*<a href="javascript:;" onClick={this.closepopup.bind(this)} className="button button-left" title="Go Back">Go Back</a>*/}
								  <a href="/" onClick={this.gobckOutletpopup.bind(this)} className="button button-left disbl_href_action" title="Go Back">Go Back</a>
								</div>
								<div className="con_first delivery_submit_cls">
								{/*<input type="button" onClick={this.selectOutlet.bind(this, 1, deliveryId)} className="button button-right delivery_submit" value="Continue" />*/}
								<input type="button" onClick={this.findOutletBasedZone.bind(this, 1, deliveryId)} className="button button-right delivery_submit" value="Continue" />
								</div>
							  </div>
							</div>
						</div>
					  </div>
					</div>
				  </div>
				  {/* Delevery Popup - end */}
				  
				  {/* Delevery Popup error - start */}
				  <div id="error-postal-popup" className="white-popup mfp-hide popup_sec error_postal_popup">
					<div className="popup_equalrw">
					  <div className="popup_ttsec">
						<div className="innervmid_in">
						  <div className="pop_title poptt_icontop text-center">
							<img src={iconUnhappy} />
							<h2 className="text-uppercase">Sorry </h2>
							<small>We cant find your postal code</small>

						  </div>
						</div>
					  </div>
					  <div className="popup_right">
						<div className="innervmid_in">
						 {/*<h4>Enter your postal code</h4>*/}
						 
						 {cookie.load("UserId") &&  <div className="address-list-cls address-list-error">
							{this.userAddressList(2)}
						    </div>}
						 
						  <form className="form_sec">
							<div className="form-group">
							  <div className={(this.state.secondaryaddresslist.length > 0)?"focus-out focused":"focus-out"}>
								<label>Enter your postal code</label>
								<input type="text" id="postalcode1" pattern="\d*" maxLength="6" className="form-control input-focus" />
								<div className="postal_error"></div>
							  </div>
							</div>
							<div className="btn_sec delivery_submit_cls delivery_submit_div">
							{/*<input type="button" onClick={this.selectOutlet.bind(this, 0, deliveryId)} className="button delivery_submit" value="Continue" />*/}
							  <input type="button" onClick={this.findOutletBasedZone.bind(this, 0, deliveryId)} className="button delivery_submit" value="Continue" />
							</div>
						  </form>
						</div>
					  </div>
					</div>
				  </div>
				  {/* Delevery Popup error - Start */}
				  
				  {/* success popup - Start */}
				  <div id="awesome-popup" className="white-popup mfp-hide popup_sec delevery_popup delevery_popup_datetime">
					<div className="popup_equalrw">
					  <div className="popup_ttsec">
						<div className="innervmid_in">
						  <div className="pop_title">
							<img className="pop-scooter-img" src={iconWin} />
							<h2 className="text-uppercase">Awesome</h2>
							{(this.state.seletedAvilablityId === deliveryId) && <small>We can Deliver !</small>}
						  </div>
						  {(this.state.seletedAvilablityId === deliveryId) ? <div className="awesome_del">
							<h5>Your Delivery Address :</h5>
							<h2>{this.state.orderDeliveryAddress}</h2>
						  </div> : <div className="awesome_del">
							<h5>Your Pickup location Is :</h5>
							<h2>{this.state.orderHandled}</h2>
						  </div>}
						</div>
					  </div>
					  <div className="popup_right">
						<div className="innervmid_in">
							
							<div className="datetime_selt_sec">
							   <div className="datetime_selt_lbl">
							    {(this.state.seletedAvilablityId === deliveryId) ? 'Select Your Delivery Date & Time' : 'Select Your Pickup Date & Time'}
						       </div> 
							   
							   {!currenturl.includes(isCheckout) && <OrderdatetimeSlot {...this.props} hdrState={this.state} setdateTimeFlg={this.setdateTimeFlg} />}
							   
							   <div className="ordrdatetime_error"></div>
							</div>
							
							<div className="btn_sec">
							  <input type="button" onClick={this.setOrderOutletDateTimeData.bind(this)} className="button" value="Continue" />
							</div>
							
						</div>
					  </div>
					</div>
				  </div>
				  {/* success popup - end */}
				  
				  {/* success popup - Start */}
				  <div id="awesome-popup-old" className="white-popup mfp-hide awesome_popup">
					<div className="popup_equalrw">
					  <div className="popup_ttsec">
						<div className="innervmid_in">
						  <div className="pop_title poptt_icontop text-center">
							<img src={iconWin} />
							<h2 className="text-uppercase">Awesome</h2>
							<small>We can Deliver !</small>
						  </div>
						  <div className="awesome_del">
							<h5>Your Delivery Address :</h5>
							<h2>{this.state.orderDeliveryAddress}</h2>
						  </div>
						  <div className="btn_sec">
							<input type="button" onClick={this.gotoProducts.bind(this)} className="button" value="Continue" />
						  </div>
						</div>
					  </div>
					</div>
				  </div>
				  {/* success popup - end */}
				  
				  {/* error Popup - start */}
				  <div id="outlet-error-popup" className="white-popup mfp-hide popup_sec warning_popup outlet_error_popup">
					<div className="custom_alert">
						<div className="custom_alertin">
							<div className="alert_height">
								<div className="alert_body">
									<img className="warning-popup-img" src={warningImg} />
									<h2 className="text-uppercase">Sorry</h2>
									<p>{'We can`t Deliver At the Moment!'}</p>
									<p>Please come back again.</p> 
									<div className="alt_btns">
									{(this.state.seletedAvilablityId === pickupId)?<a href="/" onClick={this.gobckPkupOutletpopup.bind(this)}  className="button button-right popup-modal-dismiss disbl_href_action">change outlet</a>:<a href="/" onClick={this.gobckOutletpopup.bind(this)}  className="button button-right popup-modal-dismiss disbl_href_action">change address</a>}
									</div>
								</div>
							</div>
						</div>
					</div>
				  </div>
				 {/* error Popup - end */}
				  
				  {/* Takeaway Popup - Start */}
				  <div id="takeaway-popup" className="white-popup mfp-hide popup_sec self_popup">
					<div className="order-body">
					
					  <div className="self_popup_hea_row">
						<div className="self_popup_hea_col_left">
						  <img src={takeawayWtImg} />
						</div>
						<div className="self_popup_hea_col">
						  <h2>Please Select</h2>
						  <p>Your Self Collection Outlet</p>
						</div>
					  </div>
					  
					  <div className="self_pop_row">
						<div className="self_pop_col self_pop_col_right">
						  <div className="self_pop_item">
							
							<div className="self_pop_locbx"> 
							    {/*<h4>Search Cedele Outlet.</h4>*/}
								<div className="form-group">
								  <div className="focus-out">
									<label>Search Outlet</label>
									<input type="input" className="form-control input-focus" onKeyUp={this.handleKeyPress} />
									<div className="outlet_error"></div>
								  </div>
								</div>
							</div>
							
							<div className="self_outlet">
								<h2>Nearby Outlets</h2>
								<ul className="self_outlet_inner">
								{ this.loadOutletsList() }
								</ul>
								<a className="button takeaway-btn-act disbl_href_action" id="takeaway-continue-link" href="/" onClick={this.selectDatetm.bind(this)}>Continue</a>
							</div>
							
						  </div>
						</div>
					  </div>
					  
					</div>
				  </div>
				  {/* Takeaway Popup - end */}
				  
				  {/*  Coming Soon Popup - start */}
				  <div id="comingsoon-popup" className="white-popup mfp-hide popup_sec comingsoon_popup">
					
					<div className="order-body">
					  <div className="comingsoon_popup_hea_row">
						<div className="comingsoon_popup_hea_col">
						  <h2>COMING SOON.</h2>
						</div>
					  </div>
					  <div className="comingsoon_pop_row">
						<p> can you select another availability.</p>
						<a href="/" onClick={this.closepopup.bind(this)} className="button disbl_href_action" title="Go Back">Go Back</a>
					  </div>
					 </div> 
				  </div>
				  {/* Coming Soon Popup - end */}
				  
				  {/* Warning Popup - start */}
				  <div id="warning-popup" className="white-popup mfp-hide popup_sec warning_popup">
					<div className="custom_alert">
						<div className="custom_alertin">
							<div className="alert_height">
								<div className="alert_header">Warning</div>
								<div className="alert_body">
									<img className="warning-popup-img" src={warningImg} />
									<p>By switching you are about to clear your cart.</p>
									<p>Do you wish to proceed ?</p> 
									<div className="alt_btns">
										<a href="/" className="popup-modal-dismiss button button-left disbl_href_action">No</a>
										<a href="/" onClick={this.changeAvailability.bind(this)}  className="button button-right popup-modal-dismiss disbl_href_action">Yes</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				  </div>
				 {/* Warning Popup - end */}
				
				 <div className="mbl-menu-overly"></div>
              </header>
             );
    }
}

const mapStateTopProps = (state) => {
  var zonedetailArr = Array();
  if(Object.keys(state.zonedetail).length > 0) {
		 if(state.zonedetail[0].status === 'ok') {
			 zonedetailArr = state.zonedetail[0].result_set;
		 }
  }
  
  var outletsArr = Array();
  if(Object.keys(state.outlets).length > 0) {
		 if(state.outlets[0].status === 'ok') {
			 outletsArr = state.outlets[0].result_set;
		 }
  }
  
  var alloutletsArr = Array();
  if(Object.keys(state.alloutlets).length > 0) {
		 if(state.alloutlets[0].status === 'ok') {
			 alloutletsArr = state.alloutlets[0].result_set;
		 }
  }
  
  var secondarydataArr = Array();
  if(Object.keys(state.secondaryaddress).length > 0) {
		 if(state.secondaryaddress[0].status === 'ok') {
			 secondarydataArr = state.secondaryaddress[0].result_set;
		 }
  }  
  
  return {
    globalsettings: state.settings,
    zonedetails: zonedetailArr,
    outletslist: outletsArr,
    alloutletslist: alloutletsArr,
	logindata: state.login,
    fblogin: state.fblogin,
    forgetpassword: state.forgetpassword,
    registration: state.registration,
    menudata: state.menudata,
	secondaryaddresslist: secondarydataArr
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
	getZoneDetail: (outletId, zoneId) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId });
    },
	getPickupOutlets: () => {
      dispatch({ type: GET_PICKUP_OUTLETS });
    },
	getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
	destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
	getLoginData: (formPayload) => {
      dispatch({ type: GET_LOGINDATA, formPayload });
    },
    getFbLoginData: (formPayload) => {
      dispatch({ type: GET_FBLOGINDATA, formPayload });
    },
    getRegistration: (formPayload) => {
      dispatch({ type: GET_REGISTRATION, formPayload });
    },
	getForgetPassword: (formPayload) => {
      dispatch({ type: GET_FORGET_PASSWORD, formPayload });
    },
     getMenuData: (menuslug) => {
      dispatch({ type: GET_MENUDATA, menuslug });
    },
	getSecondaryAddress: () => {
      dispatch({ type: GET_ALLUSERSECADDRDATA });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Header));
