/* eslint-disable */
import React, { Component } from 'react';
/*import Axios from 'axios';*/
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import update from 'immutability-helper'

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import MenuNavigation from "./MenuNavigation";
import ProductList from "./ProductList";
import ProductDetail from "./ProductDetail";
import { cateringId, reservationId } from "../Helpers/Config"
import { showLoader, hideLoader } from "../Helpers/SettingHelper";
import { GET_GLOBAL_SETTINGS, GET_MENU_NAVIGATION } from '../../actions';

import innerBanner from "../../common/images/inner-banner.jpg";
import phoneIcon from "../../common/images/float-icon1.png";
import directIcon from "../../common/images/float-icon2.png";
import emailIcon from "../../common/images/float-icon3.png";

const isEqual = require("react-fast-compare");
var Parser = require('html-react-parser');

class Products extends Component {

  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: [],
      selectedCategoryName: '',
      selectedNavigation: '',
      selectedSlugType: '',
	  searchSubCat: '',
	  searchProVal: '',
	  viewProductSlug: '',
	  catNavIndex: 0,
	  catslugType: '',
      catslugValue: '',
	  cartTriggerFlg: 'No'
    }
	
	var avilablityIdTxt = cookie.load('defaultAvilablityId');
	if(avilablityIdTxt === cateringId) {
		this.props.history.push('/catering');
		return ;
	}
	
	if(avilablityIdTxt === reservationId) {
		this.props.history.push('/reservation');
		return ;
	}
	
	var orderOutletId = cookie.load('orderOutletId');
	if(orderOutletId === '' || orderOutletId === undefined) {
		cookie.save("orderPopuptrigger",'Yes');
		console.log('well');
		this.props.history.push('/');
		return ;
	}

	this.props.getSettings();
	this.props.getMenuNavigationList();
  }
  
  componentWillReceiveProps(nextProps){
	  
		/*if(this.state.selectedCategoryName!==nextProps.selectedCatry){
			this.setState({selectedCategoryName: nextProps.selectedCatry});
		}*/
	  
	    let slugType = (typeof this.props.match.params.slugType !== "undefined" ? this.props.match.params.slugType : "");
		let slugValue = (typeof this.props.match.params.slugValue !== "undefined" ? this.props.match.params.slugValue : "");
		let proValue = (typeof this.props.match.params.proValue !== "undefined" ? this.props.match.params.proValue : "");
		let selectedNavigation = nextProps.selectedNavigation;
		let selectedSlugType = nextProps.selectedSlugType;
		var searchSubCat = '';
	    if(!isEqual(this.props.match.params, nextProps.match.params)) {
			 slugType = (typeof nextProps.match.params.slugType !== "undefined" ? nextProps.match.params.slugType : "");
			 slugValue = (typeof nextProps.match.params.slugValue !== "undefined" ? nextProps.match.params.slugValue : "");
			 proValue = (typeof nextProps.match.params.proValue !== "undefined" ? nextProps.match.params.proValue : "");
		}
		
		if(slugValue !== '') {
			if(slugType !== 'category' && slugType !== 'subcategory') {
				searchSubCat = slugValue;
				slugValue = slugType;
				slugType = 'category';
				/*$('.search_result').hide();*/
				var tmpVl = '';
				$('#productsearch').val(tmpVl);
				$('#clearSearch').hide();
				$('.hsearch_sec').removeClass('open');
				$('.hsearch_trigger').removeClass('active');
				setTimeout(function(){
					$(window).scrollTo($('.'+proValue), 300);
				},200);
			}
			selectedNavigation = slugValue;
		}
		
		if(slugType === '') {
			slugType = selectedSlugType;
		}
	  
		if(selectedNavigation!==this.state.selectedNavigation || this.state.selectedCategoryName!==nextProps.selectedCatry){
          showLoader('productlist-main-div','class');
		  if(proValue === '') {
			  setTimeout(function(){
					$(window).scrollTo($('.common-inner-banner'), 300);
			  },100);
		  }
		  
		  if(slugType === 'subcategory') {
		  var navIndex = nextProps.navigateMenu.findIndex(p => p.pro_subcate_slug == selectedNavigation);
		  var categoryNameTxt = (Object.keys(nextProps.navigateMenu).length > 0) ? nextProps.navigateMenu[navIndex].subcategory_name : nextProps.selectedCatry;
		  } else {
		  var navIndex = nextProps.navigateMenu.findIndex(p => p.pro_cate_slug == selectedNavigation);
		  var categoryNameTxt = (Object.keys(nextProps.navigateMenu).length > 0) ? nextProps.navigateMenu[navIndex].category_name : nextProps.selectedCatry;
		  }
		  
		  this.setState({selectedNavigation: selectedNavigation, catNavIndex: navIndex, catslugType: slugType, catslugValue: slugValue, selectedCategoryName: categoryNameTxt, searchSubCat:searchSubCat, searchProVal: proValue});
		}
		
  }
  
  componentDidMount() {
    
	/*SCROLL FIXED PRODUCT PAGE*/
	$(window).scroll(function () {
		var productlist_height = $('.productlist-main-div').offset();
	    if(productlist_height!==undefined){
			var mycustomscroll = $('.productlist-main-div').offset().top - 122,
			wind_toptxt = $(window).scrollTop();
			
			if ( wind_toptxt > mycustomscroll) {
				$('.bakery_row').addClass("catogry_row_fixed");
			} else {
				$('.bakery_row').removeClass("catogry_row_fixed");
			}
		}
	});
	
	
  }
  
  sateValChange = (field, value) => {
		if(field === 'cartflg') {
			this.setState({cartTriggerFlg: value});
		}
		if(field === 'view_pro_data' && value !== '') {
			this.setState({viewProductSlug: value}, function () { this.openProDetailPopup(); }.bind(this));
		}
  }
  
  productFlageChange = (field, value) => {
	this.setState(update(this.state, { productflage: { [field]: { $set: value } } }))
  }
  
  callPhoneOptn(phoneTxt) {
		var resultTxt = '';
		if(phoneTxt !== '') {
			if(phoneTxt.indexOf("+65") !== -1) {
				resultTxt = "tel:"+phoneTxt;
			} else if((phoneTxt.indexOf("65") !== -1) && (phoneTxt.length >= 10)) {
				resultTxt = "tel:+"+phoneTxt;
			} else {
				resultTxt = "tel:+65"+phoneTxt;
			}
		} else {
			resultTxt = 'javascript:void(0);';
		}
		
		return resultTxt;
  }
  
  clientInfoTech() {
		let settingsArr = this.props.globalsettings;
		var clientPhone = '', clientEmail = '', clientDirection = '';
		if(Object.keys(settingsArr).length > 0) {
			if(Object.keys(settingsArr[0].result_set).length > 0) {
				clientPhone = settingsArr[0].result_set.client_company_phone;
				clientEmail = settingsArr[0].result_set.client_email_address;
				clientDirection = settingsArr[0].result_set.client_company_direction_url;
			}
		}
		
		if(clientPhone !== '' || clientEmail !== '' || clientDirection !== '') {
			return(<div className="client-info-tech">
					<ul>
					{(clientPhone !== '') && <li className="client-phone-act">
					<span className="phone-show-dskp"><img src={phoneIcon} /></span><span className="phone-show-mbl"> <a href={this.callPhoneOptn(clientPhone)}><img src={phoneIcon} /></a></span>
					</li>}
					{(clientEmail !== '') && <li className="client-mail-act">
					<span className="phone-show-dskp"><img src={emailIcon} /></span><span className="phone-show-mbl"> <a href={'mailto:'+clientEmail}><img src={emailIcon} /></a></span>
					</li>}
					{(clientDirection !== '') && <li className="client-direction-act">
					<a href={clientDirection} target="_blank"><img src={directIcon} /></a>
					</li>}
					</ul>
				   </div>);
		} else {
			return '';
		}
		
  }
  
  openProDetailPopup() {
	    showLoader("comboPro-"+this.state.viewProductSlug,'Idtext');
		$("#ProductDetailMdl").modal({backdrop: 'static',keyboard: false});
  }

  render() {
	
    return (<div className="productpage-main-div">
	
			 {/* Header start */}
			 <Header cartTriggerFlg={this.state.cartTriggerFlg} sateValChange={this.sateValChange} showCatryName={this.state.selectedCategoryName} />
			 {/* Header End */}
			 
			 <div className="common-inner-blckdiv">
				<div className="common-inner-banner">
					<img src={innerBanner} />
					<p>Our Menu</p>
				</div>
				{this.clientInfoTech()}
			 </div>
			 
			 <section className="product-menu-listing">
			 <div className="container">
			 
			 <MenuNavigation {...this.props} productState={this.state} />
			 
			 <ProductList {...this.props} productState={this.state} sateValChange={this.sateValChange} />
			 
			 </div>
			 </section>
		     
		     {/* Footer section */}
		     <Footer />
			 
			 
			 <ProductDetail productState={this.state} sateValChange={this.sateValChange} />
			 
		  </div>)
  }

}

const mapStateTopProps = (state) => {
  var tempArr = Array();var menu_slug = '';var menu_type = '';
  var navigateRst = Array();var navigateCmn = Array();
  var selectedCatry = '';
  if(Object.keys(state.product).length > 0) {
	  var tempArr = (!('menuNavigation' in state.product[0])) ? Array() : state.product[0].menuNavigation;
	  if(Object.keys(tempArr).length > 0) {
		 if(tempArr[0].status === 'ok') {
			 navigateRst = tempArr[0].result_set;
			 navigateCmn = tempArr[0].common;
			 if(tempArr[0].result_set[0].menu_type == "main") {
				 selectedCatry = tempArr[0].result_set[0].category_name;
				 menu_slug = tempArr[0].result_set[0].pro_cate_slug;
				 menu_type = 'category';
			 } else {
				 selectedCatry = tempArr[0].result_set[0].subcategory_name;
				 menu_slug = tempArr[0].result_set[0].pro_subcate_slug;
				 menu_type = 'subcategory';
			 }
		 }
	  }
  }
  
  return {
	globalsettings: state.settings,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    selectedCatry: selectedCatry,
    selectedNavigation: menu_slug,
    selectedSlugType: menu_type
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },   
    getMenuNavigationList: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(Products);