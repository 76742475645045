/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { appId, apiUrl, deliveryId, cateringId, pickupId, apiUrlV2, CountryTxt, baseUrl, timThumpUrl } from "../../Helpers/Config"
import { getReferenceID, showLoader, hideLoader, callImage, showAlert, showCartItemCount, showCustomAlert, stripslashes, smoothScroll } from "../../Helpers/SettingHelper";
import cookie from 'react-cookies';
import axios from 'axios';
var qs = require('qs');
var Parser = require('html-react-parser');
/*import Select from 'react-select';*/

import { GET_ALL_OUTLETS, GET_CATERING_HALL } from '../../../actions';
import CantDlyImg from "../../../common/images/Cant_Deliver.png";
import ClockImg from "../../../common/images/time.png";
import NoimgImg from "../../../common/images/noimg-470x240.jpg";
import Datetimelist from "./Datetimelist";

class Venuedetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            catering_postal_code: "", catering_address_line: '', catering_floor_no: '', catering_unit_no: '',outletlist: [], catering_outlet_id: '', is_single_outlet: 'No', CatOutletDropDownHtml: '', catering_outlet_address: '', catering_selected_outlet: '', catering_outletaddress_line: '',catering_outlet_postalcode: '', catering_outlet_name: '', catering_hallList: [], commonhallurl: '', catering_hall_id: '', catering_hall_name: '', catering_hall_postalcode: '', catering_halladdress_line: '', catering_halladdress: '', catering_hall_pax: '', catering_hall_charge: '', date_flag_key: "START", catering_date: "", catering_time: '',  breaktime_enable: 'No', breaktime_count: 1, breaktime_list: [], break_timeHtml: '',selected_outlet_incart: '', trigger_venue_submit: 'No', catering_event_type: '', catering_date_changflg: 'initail', hall_scearch_text: ''
        };
		
		if(typeof cookie.load('cateringPostalCode') !== 'undefined') {
			this.state['catering_postal_code'] = cookie.load('cateringPostalCode');
		}
		
    }
	
	componentWillReceiveProps(nxxtProps) {

		if(Object.keys(nxxtProps).length > 0) {
			var categoryStateArr = nxxtProps.categoryState;
			for (var key in categoryStateArr) {
				if(key !== 'catering_outlet_id') {
					this.state[key] = categoryStateArr[key];
				}
			}
			
			if(categoryStateArr.trigger_venue_submit === 'Yes') {
				this.props.sateValChangefun('venue_submit', 'No');
				this.submitVenueData();
			}
		}
		
		var cartOutletId = this.state.cart_outlet_id;
		var cartHallId = this.state.cart_hall_id;
		if(cartOutletId !== '' || cartHallId !== '') {
			this.setOutletValue();
		}
		
		if(this.state.catering_hallList !== nxxtProps.cateringhall) {
			this.setState({catering_hallList: nxxtProps.cateringhall, commonhallurl: nxxtProps.commonhallurl});
		}
		
		if(this.state.outletlist !== nxxtProps.outletslist) {
			var CatOutletHtml = "<option value=''> Select Outlet </option>";
			var outletArr = nxxtProps.outletslist;
			if(Object.keys(outletArr).length > 0) {
				outletArr.map((loadData) => 
                    CatOutletHtml+="<option value='"+loadData.oa_outlet_id+'~'+loadData.outlet_postal_code+'~'+stripslashes(loadData.outlet_name).replace("'", "`")+'~'+stripslashes(loadData.outlet_address_line1)+"' >"+stripslashes(loadData.outlet_name)+"</option>"
			     );
				 if(outletArr.length == 1) {
					 var unitNum = ''
					 if(outletArr[0].outlet_unit_number1 !== '' || outletArr[0].outlet_unit_number2 !== '') {	
						unitNum = (outletArr[0].outlet_unit_number2 !== '')	? " #"+outletArr[0].outlet_unit_number1+"-"+outletArr[0].outlet_unit_number2 : " #"+outletArr[0].outlet_unit_number1; 
					 }
					 var addrsTxt = stripslashes(outletArr[0].outlet_address_line1)+unitNum+' '+CountryTxt+', '+outletArr[0].outlet_postal_code;
					 this.setState({catering_outlet_id: outletArr[0].oa_outlet_id, catering_outlet_postalcode: outletArr[0].outlet_postal_code, catering_outlet_name: stripslashes(outletArr[0].outlet_name), catering_outletaddress_line: stripslashes(outletArr[0].outlet_address_line1), catering_outlet_address: addrsTxt, is_single_outlet: 'Yes'});
				 }
			}
			
			var eventtype = (typeof cookie.load('catering_eventType') !== 'undefined' ? cookie.load('catering_eventType') : 'venue');
			
			var outletDropDown = Parser(CatOutletHtml);
			var isSngl = (outletArr.length == 1) ? 'Yes' : 'No';
			this.setState({outletlist: nxxtProps.outletslist, CatOutletDropDownHtml: outletDropDown}, function () { this.changeEventType(eventtype, ''); }.bind(this));
			this.checkOutletAvailability(nxxtProps.outletslist, isSngl);
		}
		
		if(Object.keys(nxxtProps).length > 0) {
			var categoryStateArr = nxxtProps.categoryState;
			/*if(categoryStateArr.trigger_venue_type === 'Yes') {
				this.props.sateValChangefun('venueTypeChanged', 'No');
				var eventtype = (typeof cookie.load('catering_eventType') !== 'undefined' ? cookie.load('catering_eventType') : 'venue');
				this.changeEventType(eventtype, '');
			}*/
		}
		
	}
	
	componentDidMount()
	{
		
	  this.props.getAllOutlets(cateringId);
	  var hallSlg = '';
	  this.props.getCateringHalls(hallSlg);
	  
	}
	
    /* Change records */
	changeEventType(eventtype, clkType) 
	{
		 if(clkType !== '' && clkType === 'change_event_type') {
			var totalItems = cookie.load('cartTotalItems');
			if(parseInt(totalItems)>0) {
				$.magnificPopup.open({
					items: {
						src: '#clear_popup_id'
					},
					type: 'inline'
				});
			} else {
				this.setEventType(eventtype);
			}
			
		 } else {

		 	this.setEventType(eventtype);	
		}
		
		
		this.setState({catering_event_type: eventtype});
		
	}
	
	setEventType(eventtype) {
		
		this.resetVenueHallDat();
		console.log(eventtype);
		if(eventtype === 'hall') {
			 document.getElementById("ownvenueDetails").style.display = "none";
			 document.getElementById("cateringHallList").style.display = "block";
		 } else {
			 document.getElementById("ownvenueDetails").style.display = "block";
			 document.getElementById("cateringHallList").style.display = "none";
			 var outletListDat = this.state.outletlist;
			 if(this.state.is_single_outlet === 'Yes' && outletListDat.length == 1) {
				var unitNum = ''
				if(outletListDat[0].outlet_unit_number1 !== '' || outletListDat[0].outlet_unit_number2 !== '') {	
					unitNum = (outletListDat[0].outlet_unit_number2 !== '')	? " #"+outletListDat[0].outlet_unit_number1+"-"+outletListDat[0].outlet_unit_number2 : " #"+outletListDat[0].outlet_unit_number1; 
				}
				var addrsTxt = stripslashes(outletListDat[0].outlet_address_line1)+unitNum+' '+CountryTxt+', '+outletListDat[0].outlet_postal_code;
				
				this.setState({catering_outlet_id: outletListDat[0].oa_outlet_id, catering_outlet_postalcode: outletListDat[0].outlet_postal_code, catering_outlet_name: stripslashes(outletListDat[0].outlet_name), catering_outletaddress_line: stripslashes(outletListDat[0].outlet_address_line1), catering_outlet_address: addrsTxt});
			 }
		 }
		 
		 this.resetErrorClass();
		 cookie.save('catering_eventType', eventtype);
		
	}
	
	/* check outlet is available or not */
	checkOutletAvailability(outletArr, isSingleOutlet) 
	{
		 document.getElementById("no_outlet_info").style.display = "none";
		 document.getElementById("outlet_list_div").style.display = "block";
		 if(outletArr.length === 0) {
			 document.getElementById("no_outlet_info").style.display = "block";
			 document.getElementById("outlet_list_div").style.display = "none";
		 } else if(outletArr.length === 1 && isSingleOutlet === 'Yes') {
			 document.getElementById("outlet_list_div").style.display = "none";
		 }
	}

	/* Change records */
	defaultEventType(type) 
	{
		var eventtype = (typeof cookie.load('catering_eventType') !== 'undefined' ? cookie.load('catering_eventType') : 'venue');
		 if(type === 'hall') {
			 return (eventtype === 'hall'? 'hall-check-div active' : 'hall-check-div');
		 } else {
			 return (eventtype === 'venue'? 'venue-check-div active' : 'venue-check-div');
		 }
	}
	
	cartDestoryFun() {
		
		var postCrtObject = {};
			postCrtObject = {
				'app_id' : appId,
			}
		if (typeof cookie.load('UserId') === 'undefined') {
			postCrtObject['reference_id'] = getReferenceID();
		} else {
			postCrtObject['customer_id'] = cookie.load('UserId');
		}
		
		axios.post(apiUrlV2 + "cateringcart/destroy", qs.stringify(postCrtObject))
		.then(res => {
			if (res.data.status === "ok") {
				cookie.save('fromVenueFlg','Yes');
				showCustomAlert('success','Nice! Your cart is empty');
				$.magnificPopup.close();
				var cartDetails = Array();
				showCartItemCount(cartDetails);
				window.location.reload();
				
			} else {
				$.magnificPopup.close();
				showCustomAlert('error','Sorry! Products can`t update your cart');
			}
		})
		.catch(function (error) {
			console.log(error);
			showCustomAlert('error','something went wrong');
			$.magnificPopup.close();
		});
		
	}
	
	/* Change records */
	setOutletValue() 
	{
		 if(cookie.load('catering_eventType') === 'hall' && this.state.cart_hall_id !== '') {   
			var hallDetail = this.state.cart_hall_txt;
			if(hallDetail !== '') {
				var splitedHallArr = hallDetail.split('~');
			    var OutltIdTextValue = splitedHallArr[0];
			    var OutletNameTxt = splitedHallArr[1];
			    var HallId = splitedHallArr[2];
			    var HallName = splitedHallArr[3];
			    var HallPostalcd = splitedHallArr[4];
			    var HallAddrsLn = splitedHallArr[5];
			    var HallPrice = splitedHallArr[6];
			    var HallPax = splitedHallArr[7];
				
				var HallAddrs = HallAddrsLn+' '+CountryTxt+', '+HallPostalcd;
				
				this.setState({catering_outlet_id: OutltIdTextValue, catering_outlet_name: OutletNameTxt, catering_hall_id: HallId, catering_hall_name: HallName, catering_hall_postalcode: HallPostalcd, catering_halladdress_line: HallAddrsLn, catering_halladdress: HallAddrs, catering_hall_pax: HallPax, catering_hall_charge: HallPrice});
				
				cookie.save('cateringOutletId', OutltIdTextValue);
				cookie.save('cateringOutletName', OutletNameTxt);
				
				cookie.save('cateringHallId', HallId);
				cookie.save('cateringHallName', HallName);
				cookie.save('cateringHallPostalcode', HallPostalcd);
				cookie.save('cateringHalladdressLine', HallAddrsLn);
				cookie.save('cateringHalladdress', HallAddrs);
				cookie.save('cateringHallPax', HallPax);
				cookie.save('cateringHallCharge', HallPrice);
			}
			
		 } else {
			var ouLtvl = this.state.cart_outlet_txt;
			var unitNum = this.state.cart_outlet_unitNum;
			var outletIdvl = '', outletAddrsLn='', outletAddrs='', outletPostCd='', outletName='';
			
			if(ouLtvl !== '') {
				var splitedOutletArr = ouLtvl.split('~');
			    outletIdvl = splitedOutletArr[0];
			    outletPostCd = splitedOutletArr[1];
			    outletName = splitedOutletArr[2];
			    outletAddrsLn = splitedOutletArr[3];
				
				outletAddrs = outletAddrsLn+unitNum+' '+CountryTxt+', '+outletPostCd;
			}
			
			this.setState({catering_selected_outlet: ouLtvl, catering_outlet_id: outletIdvl, catering_outlet_postalcode: outletPostCd, catering_outlet_name: outletName, catering_outletaddress_line: outletAddrsLn, catering_outlet_address: outletAddrs});
			
			if(this.state.catering_date_changflg === 'initail') {
				this.setState({catering_date: '', catering_time: ''});
			}
			
			cookie.save('cateringOutletId', outletIdvl);
			cookie.save('cateringOutletName', outletName);
			
			cookie.save('cateringOutletPostalcode', outletPostCd);
			cookie.save('cateringOutletaddressLine', outletAddrsLn);
			cookie.save('cateringOutletAddress', outletAddrs);
		 }
	}
	
	/* Change records */
	handleChange(event) 
	{
		if(event.target.name === 'catering_outlet_id') {
			
			var totalItems = cookie.load('cartTotalItems');
			if(parseInt(totalItems)>0) {
				$.magnificPopup.open({
					items: {
						src: '#clear_popup_id'
					},
					type: 'inline'
				});

			} else {

			this.resetErrorClass();
			
			showLoader('datepicker_main_div','class');
			
			var ouLtvl = event.target.value;
			var outletIdvl = '', outletAddrsLn='', outletAddrs='', outletPostCd='', outletName='', errorInfo='block', temHlval='';
			if(ouLtvl !== '') {
				var splitedOutletArr = ouLtvl.split('~');
			    outletIdvl = splitedOutletArr[0];
			    outletPostCd = splitedOutletArr[1];
			    outletName = splitedOutletArr[2];
			    outletAddrsLn = splitedOutletArr[3];
				
				outletAddrs = outletAddrsLn+' '+CountryTxt+', '+outletPostCd;
				errorInfo = 'none';
			}
			
			this.setState({catering_date: '', catering_time: '', catering_selected_outlet: ouLtvl, catering_outlet_id: outletIdvl, catering_outlet_postalcode: outletPostCd, catering_outlet_name: outletName, catering_outletaddress_line: outletAddrsLn, catering_outlet_address: outletAddrs});
			
			document.getElementById("error_info_div").style.display = errorInfo;
			
			this.props.sateValChangefun('outlet_id', outletIdvl);
			this.props.sateValChangefun('hall_id', temHlval);
		   }
		}
	}
	
	displaySingleOutletInfo() {
		var eventType = cookie.load('catering_eventType');
		
		if(this.state.is_single_outlet === 'Yes' && eventType === 'venue') {
			return (<div className="single_outlet_info"> 
						<span className="mainacc_toptext"><p>You are making a catering order from</p></span>
						<h4>{this.state.catering_outlet_name}</h4>
						<p>{this.state.catering_outlet_address}</p>
					</div>);
		}
	}
	
	getHallList() {
		if(this.state.catering_hallList.length > 0) {
			
			const HallListhtml = this.state.catering_hallList.map((hallList, index) =>
								 <li key={index} id={"hall-"+hallList.catering_hall_setup_id} className={this.activeHall(hallList.catering_hall_setup_id)}>
								 
								 <a className="view-rooms" href="javascript:void(0)" onClick={this.openHallPopup.bind(this,index)}>
								  {/*callImage(this.state.commonhallurl.image_source, hallList.catering_hall_image, 176, 143, timThumpUrl)*/}
								  {(hallList.catering_hall_image !== '') ? <img src={this.state.commonhallurl.image_source+hallList.catering_hall_image} /> : <img src={NoimgImg} />}
								  <h4>{hallList.catering_hall_title} </h4>
								  <span className="click-here"> Click here to view</span>
								 </a>
								   
							     <div id={"hall-det"+index} className="hall-select-popup white-popup mfp-hide popup_sec">
										<div className="hall-select-popup-inner"> 
										
											  {/*(hallList.catering_hall_image !== '') ? callImage(this.state.commonhallurl.image_source, hallList.catering_hall_image, 520, 300, timThumpUrl) : ""*/}

											  {(hallList.catering_hall_image !== '') ? <img src={this.state.commonhallurl.image_source+hallList.catering_hall_image} /> : ""}
											  
											  <h3 className="pop-title" style={{marginTop: "15px"}}>{hallList.catering_hall_title}</h3>  
											  <div> 
												  <p>{hallList.catering_hall_address+' '+CountryTxt+', '+hallList.catering_hall_postal_code}</p>
												  <p>{(hallList.catering_hall_pax !== '' && hallList.catering_hall_pax !== '0') && 'Pax : '+hallList.catering_hall_pax+','} {(hallList.catering_hall_price !== '' && hallList.catering_hall_price !== '0') && 'Hall Charge : '+hallList.catering_hall_price}</p>
											  </div>
											  <div className="hall-description">
												{hallList.catering_hall_description}
											  </div>
											  
											  <input type="button" className="button" onClick={this.selectHall.bind(this,hallList)} value="select" />
											 
										 </div>
									</div>
								   
								  </li>); 
			
			return HallListhtml;					  
								  
		} else {
			return (<div className="categorylist_norecord"> Catering Halls was not available. </div>);
		}
		
	}
	
	activeHall(hallSetupId) {
		var HallId = this.state.catering_hall_id;
		var clsName = (HallId === hallSetupId) ? "hall-li active" : "hall-li";
		return clsName;
	}
	
	openHallPopup(index) {
		
		this.resetErrorClass();
		
		var idText = '#hall-det'+index;
		$.magnificPopup.open({
			items: {
				src: idText
			},
			type: 'inline'
		});
	}
	
	selectHall(hallDat) {
		
		var totalItems = cookie.load('cartTotalItems');
		if(parseInt(totalItems)>0) {
			$.magnificPopup.open({
				items: {
					src: '#clear_popup_id'
				},
				type: 'inline'
			});

		} else {
		
		var OutletId = hallDat.outlet_id;
		var OutletNameTxt = stripslashes(hallDat.outlet_name);
		
		var HallId = hallDat.catering_hall_setup_id;
		var HallName = stripslashes(hallDat.catering_hall_title);
		var HallPostalcd = hallDat.catering_hall_postal_code;
		var HallAddrsLn = stripslashes(hallDat.catering_hall_address);
		var HallPrice = hallDat.catering_hall_price;
		var HallPax = hallDat.catering_hall_pax;
		
		var HallAddrs = HallAddrsLn+' '+CountryTxt+', '+HallPostalcd;
		
		this.setState({catering_outlet_id: hallDat.outlet_id, catering_outlet_name: OutletNameTxt, catering_hall_id: HallId, catering_hall_name: HallName, catering_hall_postalcode: HallPostalcd, catering_halladdress_line: HallAddrsLn, catering_halladdress: HallAddrs, catering_hall_pax: HallPax, catering_hall_charge: HallPrice});
		
		this.props.sateValChangefun('outlet_id', hallDat.outlet_id);
		this.props.sateValChangefun('hall_id', HallId);
		
		/*$('.hall-li').removeClass('hall-active');
		$('#hall-'+HallId).addClass('hall-active');*/
		var magnificPopup = $.magnificPopup.instance;
		magnificPopup.close();
		}
	}

	resetVenueHallDat() {
		
		var tempVal = '';
		
		this.setState({catering_selected_outlet: tempVal, catering_outlet_id: tempVal, catering_outlet_name: tempVal, catering_outlet_postalcode: tempVal, catering_outletaddress_line: tempVal, catering_outlet_address: tempVal, catering_hall_id: tempVal, catering_hall_name: tempVal, catering_hall_postalcode: tempVal, catering_halladdress_line: tempVal, catering_halladdress: tempVal, catering_hall_pax: tempVal,catering_hall_charge: tempVal});
		
		/*$('.hall-li').removeClass('active');*/
	}
	
	resetErrorClass() {
		$('.choose_event_data, .date_list_maindiv, .breaktime_main_div').removeClass('pkgerror_out');
		$('.choose_event_data, .date_list_maindiv, .breaktime_main_div').removeClass('bdr_bf_select');
		$('.choose_event_data, .date_list_maindiv, .breaktime_main_div').addClass('bdr_bf_select');
		$('.venuehall_error, .datetimediv_error, .breaktimediv_error').hide();
	}
	
	submitVenueData() {
		
		var eventType = cookie.load('catering_eventType');
		var isError = "No"; 
		
		/* validation start */
		if(((eventType === "hall") && (this.state.catering_outlet_id === '' || this.state.catering_hall_id === '' || this.state.catering_hall_postalcode === '' || this.state.catering_hall_charge === '')) || ((eventType === "venue") && (this.state.catering_outlet_id === '' || this.state.catering_outlet_postalcode === '' || this.state.catering_outlet_address === ''))) {
				isError = "Yes";
				$('.choose_event_data').removeClass('bdr_bf_select');
				$('.choose_event_data').addClass('pkgerror_out');
				$('.venuehall_error').show();
				window.scrollTo(0, 500);
				return false;
		}
		
		if(this.state.catering_date === '' || this.state.catering_time === '') {
				isError = "Yes";
				$('.date_list_maindiv').removeClass('bdr_bf_select');
				$('.date_list_maindiv').addClass('pkgerror_out');
				$('.datetimediv_error').show();
				window.scrollTo(0, 630);
				return false;
		}	
		
		var breaktimeList = Array();
		if(this.state.breaktime_enable === 'Yes') {
			var listCtArr = Array(); var dulb_entry = 'no';
			$('.breaktime_listcls').each(function(index){
				var brkval = $(this).val();
				if(listCtArr.indexOf(brkval) != -1) {
					dulb_entry = 'yes';
				}
				if(brkval != '' && brkval !== null) {
					listCtArr.push(brkval);
					breaktimeList.push(brkval);
				}
			});
			
			var brkCountTxt = this.state.breaktime_count;
			brkCountTxt = (brkCountTxt != '') ? parseInt(brkCountTxt) : 0;
			var errorTxt = '';
			if(brkCountTxt !== listCtArr.length) {
				errorTxt = 'Please select Break Time';
			} else if(dulb_entry === 'yes') {
				errorTxt = 'Please select valide Break Time';
			}
			
			if(errorTxt !== '') {
				isError = "Yes";
				$('.breaktime_main_div').removeClass('bdr_bf_select');
				$('.breaktime_main_div').addClass('pkgerror_out');
				$('.breaktimediv_error').show();
				$('.breaktime_error_msg').html(errorTxt);
				return false;
			}
		}
		this.setState({'breaktime_list': breaktimeList});
		
		
		/* validation end */
		if(isError === "No") {
			
			var categoryId = cookie.load('catering_categoryId');
			if(typeof categoryId !== 'undefined' && categoryId !== '') {
				var catFlArr = Array();
				catFlArr['category_id'] = categoryId;
				catFlArr['categoryPryId'] = cookie.load('catering_categoryPryId');
				catFlArr['category_name'] = cookie.load('catering_categoryName');
				this.props.changeCatgryDet(catFlArr);
			}
			
			
			this.props.sateValChangefun('catering_date', this.state.catering_date);
			this.props.sateValChangefun('catering_time', this.state.catering_time);
			this.props.sateValChangefun('breaktime_enable', this.state.breaktime_enable);
			this.props.sateValChangefun('breaktime_count', this.state.breaktime_count);
			this.props.sateValChangefun('breaktime_list', breaktimeList);
			this.props.sateValChangefun('break_timeHtml', this.state.break_timeHtml);
			this.props.sateValChangefun('outlet_id', this.state.catering_outlet_id);
			this.props.sateValChangefun('hall_id', this.state.catering_hall_id);
			
			cookie.save('cateringOutletId', this.state.catering_outlet_id);
			cookie.save('cateringOutletName', this.state.catering_outlet_name);
			
			cookie.save('cateringSelectedOutlet', this.state.catering_selected_outlet);
			cookie.save('cateringOutletPostalcode', this.state.catering_outlet_postalcode);
			cookie.save('cateringOutletaddressLine', this.state.catering_outletaddress_line);
			cookie.save('cateringOutletAddress', this.state.catering_outlet_address);
			
			cookie.save('cateringHallId', this.state.catering_hall_id);
			cookie.save('cateringHallName', this.state.catering_hall_name);
			cookie.save('cateringHallPostalcode', this.state.catering_hall_postalcode);
			cookie.save('cateringHalladdressLine', this.state.catering_halladdress_line);
			cookie.save('cateringHalladdress', this.state.catering_halladdress);
			cookie.save('cateringHallPax', this.state.catering_hall_pax);
			cookie.save('cateringHallCharge', this.state.catering_hall_charge);
			
			cookie.save('cateringDate', this.state.catering_date);
			cookie.save('cateringTime', this.state.catering_time);
			cookie.save('breaktimeEnable', this.state.breaktime_enable);
			cookie.save('breaktimeCount', this.state.breaktime_count);
			cookie.save('breaktimeList', breaktimeList);
			
			var dateTimeTrigger = cookie.load('dateTimeTrigger');
			if(dateTimeTrigger == 'yes') {
				cookie.remove("dateTimeTrigger");
				this.props.setActiveTab(4);
			} else {
				smoothScroll(500,70);
				this.props.setActiveTab(2);
			}
			
		}
		
	}
	
	resetVenueHallDat_inCookie() {
		
			var tempVal = '';
			cookie.save('cateringOutletId', tempVal);
			cookie.save('cateringOutletName', tempVal);
			
			cookie.save('cateringSelectedOutlet', tempVal);
			cookie.save('cateringOutletPostalcode', tempVal);
			cookie.save('cateringOutletaddressLine', tempVal);
			cookie.save('cateringOutletAddress', tempVal);
			
			cookie.save('cateringHallId', tempVal);
			cookie.save('cateringHallName', tempVal);
			cookie.save('cateringHallPostalcode', tempVal);
			cookie.save('cateringHalladdressLine', tempVal);
			cookie.save('cateringHallPax', tempVal);
			cookie.save('cateringHallCharge', tempVal);
			
			cookie.save('cateringDate', tempVal);
			cookie.save('cateringTime', tempVal);
			cookie.save('breaktimeEnable', tempVal);
			cookie.save('breaktimeCount', tempVal);
			cookie.save('breaktimeList', tempVal);
	}
	
	dateTmChange = (field, value) => {
		if(field === 'catering_date') {
			this.setState({catering_date: value});
			this.props.sateValChangefun('catering_date', value);
		}
		else if(field === 'catering_time') {
			this.setState({catering_time: value});
			this.props.sateValChangefun('catering_time', value);
		}
		else if(field === 'date_changflg') {
			this.setState({catering_date_changflg: value});
		}
		else if(field === 'breaktime_enable') {
			this.setState({breaktime_enable: value});
			this.props.sateValChangefun('breaktime_enable', value);
		}
		else if(field === 'breaktime_count') {
			this.setState({breaktime_count: value});
			this.props.sateValChangefun('breaktime_count', value);
		}
		else if(field === 'break_timeHtml') {
			this.setState({break_timeHtml: value});
			this.props.sateValChangefun('break_timeHtml', value);
			/*if(this.state.cart_outlet_id !== '') {
				this.props.sateValChangefun('break_timeHtml', this.state.break_timeHtml);
			}*/
		}
    }
	
	
	setPropActiveTab(tagvalue) {
		var tabflag = this.props.categoryState.active_tabflag;
		if(parseInt(tabflag) !== parseInt(tagvalue)) {
			this.props.setActiveTab(parseInt(tagvalue));
		} else {
			this.props.sateValChangefun('active_tabflag', 0);
		}
	}
	
    /* ViewProducts */
	
    render() {

        return (<div>
				 {/*<h4 className={"tab_mobtrigger tab_mobtrigger_cls "+this.props.showsActiveHdr(2)} onClick={this.setPropActiveTab.bind(this,2)} >VENUE DETAILS<i></i></h4>*/}	
				  <div className="container_720 tb-padding tab_mobrow" style={{display:this.props.showsActiveDiv(1)}}>
				  
				   <h3 className="choose-eventtype">Choose Event Type & Location</h3>
				  
				   { /* choose-location start */ }
					<div className="choose_location choose_event_data bdr_bf_select">
						
						<div className="event-type-maindiv two-button-row">
						
						    <div className={this.defaultEventType('venue')} >
							  <a href="javascript:;" onClick={this.changeEventType.bind(this,'venue','change_event_type')} className="button button-left" title="Own Venue">Outdoor Catering</a>
							</div>
						
							<div className={this.defaultEventType('hall')}>
							  <a href="javascript:;" onClick={this.changeEventType.bind(this,'hall','change_event_type')} className="button button-left" title="Hall Location">Indoor Catering</a>
							</div>
						
						</div>
						
						<div className="form-group">
							<div className="ownvenue_details" id="ownvenueDetails">
								<div id='no_outlet_info' className="errorInfo-div">
								 <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> <span id="info_span">Sorry!. Outlets didn't available for catering..</span>
								</div>
								<div id='error_info_div' className="errorInfo-div">
								 <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> <span id="error_span">Please select the outlet.</span>
								</div>
								
								<div className="ownvenue-inner-div" id="outlet_list_div">
									<h4>Choose Outlet</h4>
									
									<div className="form-group custom_select" id="outlet_list_div12">
										<select value={this.state.catering_selected_outlet} name="catering_outlet_id" className="form-control" onChange={this.handleChange.bind(this)}>
											{this.state.CatOutletDropDownHtml}
										</select>
									</div>
								</div>
								
								{this.displaySingleOutletInfo()}
								
							</div>
						</div>
						
						
						<div className="catering_hall_list" id="cateringHallList" >
							
							<h4>Choose Outlet</h4>
							
							{/* <h4>Choose Location</h4>
							
							<p>Private function rooms available with a capacity ranging from a minimum of 20 pax to a maximum  of 500 pax. ( Subject to availability )</p>
							
							<p>Kindly take note that room rental charges may apply. Minimum order requirement for Non Members:</p> */}
							
							<div className="function-rooms">
							<ul>
								{this.getHallList()}
							</ul>
							</div>
							
									
						</div>
				   
						<div className="pkgerror_txt venuehall_error" style={{display:'none'}}>
									<span className="venuehall_error_msg">Please select Your Event Location</span></div>	
					</div> 
					{ /* choose-location end */ }
					
					
					{ /* date_list and Break time div start */ }
					<div className="datelist_bracktime">
					<Datetimelist venueState={this.state} onChangeFl={this.dateTmChange}  resetErrorFn={this.resetErrorClass} setActiveProTab={this.props.setActiveTab}/>
					</div>
					{ /* date_list div end */ }
					
					
					<div className="continue">
						<a href="javascript:void(0)" className="button" onClick={this.submitVenueData.bind(this)}>continue</a>
					</div>
					
					<div className="white-popup mfp-hide popup_sec" id="clear_popup_id" style={{maxWidth: 500}}><div className="custom_alert"><div className="custom_alertin"><div className="alert_height"><div className="alert_header" style={{textAlign: 'center'}}>Wait a second !</div><div className="alert_body"><img src={CantDlyImg} /><p>By switching you are about to clear your cart.</p><p>Do you wish to proceed ?</p><div className="alt_btns"><a href="javascript:void(0);" className="button popup-modal-dismiss">Cancel</a><a href="javascript:void(0);" className="button btn_yellow" onClick={this.cartDestoryFun.bind(this)}>Yes Proceed</a></div></div></div></div></div></div>

				  </div> 
				</div>);
     }
}

const mapStateTopProps = (state) => {
  var outletsArr = Array();
  if(Object.keys(state.alloutlets).length > 0) {
		 if(state.alloutlets[0].status === 'ok') {
			 outletsArr = state.alloutlets[0].result_set;
		 }
  }
  
  var hallArr = Array();var commonhallArr = Array();
  if(Object.keys(state.cateringhall).length > 0) {
		 if(state.cateringhall[0].status === 'ok') {
			 hallArr = state.cateringhall[0].result_set;
			 commonhallArr = state.cateringhall[0].common;
		 }
  }
  
  return {
    outletslist: outletsArr,
    cateringhall: hallArr,
    commonhallurl: commonhallArr
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
	getCateringHalls: (hallSlug) => {
      dispatch({ type: GET_CATERING_HALL, hallSlug });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(Venuedetails);