/* eslint-disable */
import React, { Component } from 'react';

import { Link} from 'react-router-dom';

import Slider from "react-slick";

import { stripslashes, hideLoader } from "../Helpers/SettingHelper";

import Header from "../Layout/Header"
import Footer from "../Layout/Footer"

var Parser = require('html-react-parser');


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
/*import {
  requestPageData,
} from '../modules/ui/actions'; */

import innerBanner from "../../common/images/inner-banner.jpg";
import phoneIcon from "../../common/images/float-icon1.png";
import directIcon from "../../common/images/float-icon2.png";
import emailIcon from "../../common/images/float-icon3.png";

import { GET_GLOBAL_SETTINGS, GET_REQUESTPAGEDATA} from '../../actions';


import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';



class Pages extends Component {

    constructor(props) {
        super(props);
		this.state = {pagedata:[],pagedetail:'',pagetitle_txt:''};
		const {page_slug}= this.props.match.params;
		this.props.getSettings();
		this.props.getRequestpage(page_slug);
    }

     

    componentDidMount() {
		 $('.dvLoadrCls').show();
		 setTimeout(function(){ $('.test-popup-link').magnificPopup({type:'image'}); }, 2000);
    }
    
    componentWillReceiveProps(nextProps) {
		 if (nextProps.match.params.page_slug !== this.props.match.params.page_slug) {
			  if ($(".trigger_menu").length > 0) {
                $('.trigger_menu').toggleClass('active');
				if ($('.hmenu_list').hasClass('open')) {
                $('.mega_menu').slideUp();
				}
				$('.hmenu_list').toggleClass('open');
			  }
			  this.props.getRequestpage(nextProps.match.params.page_slug);
		 }
		 
		 if(nextProps.pagedata !== this.state.pagedata) {
			 $('.dvLoadrCls').fadeOut(500);
			 var pageDetails = '';
			 var pageTitleTxt = '';
			 if(Object.keys(nextProps.pagedata).length > 0) {
				 var pagedataTxt = nextProps.pagedata[0].cmspage_description;
				 pageTitleTxt = nextProps.pagedata[0].cmspage_title;
				 pageDetails = (pagedataTxt !== '') ? Parser(pagedataTxt) : '';
			 }
			 
			 this.setState({pagedata: nextProps.pagedata, pagedetail: pageDetails, pagetitle_txt: pageTitleTxt});
		 }
	}
	
	
	
  callPhoneOptn(phoneTxt) {
		var resultTxt = '';
		if(phoneTxt !== '') {
			if(phoneTxt.indexOf("+65") !== -1) {
				resultTxt = "tel:"+phoneTxt;
			} else if((phoneTxt.indexOf("65") !== -1) && (phoneTxt.length >= 10)) {
				resultTxt = "tel:+"+phoneTxt;
			} else {
				resultTxt = "tel:+65"+phoneTxt;
			}
		} else {
			resultTxt = 'javascript:void(0);';
		}
		
		return resultTxt;
  }
  
  clientInfoTech() {
		let settingsArr = this.props.globalsettings;
		var clientPhone = '', clientEmail = '', clientDirection = '';
		if(Object.keys(settingsArr).length > 0) {
			if(Object.keys(settingsArr[0].result_set).length > 0) {
				clientPhone = settingsArr[0].result_set.client_company_phone;
				clientEmail = settingsArr[0].result_set.client_email_address;
				clientDirection = settingsArr[0].result_set.client_company_direction_url;
			}
		}
		
		if(clientPhone !== '' || clientEmail !== '' || clientDirection !== '') {
			return(<div className="client-info-tech">
					<ul>
					{(clientPhone !== '') && <li className="client-phone-act">
					<span className="phone-show-dskp"><img src={phoneIcon} /></span><span className="phone-show-mbl"> <a href={this.callPhoneOptn(clientPhone)}><img src={phoneIcon} /></a></span>
					</li>}
					{(clientEmail !== '') && <li className="client-mail-act">
					<span className="phone-show-dskp"><img src={emailIcon} /></span><span className="phone-show-mbl"> <a href={'mailto:'+clientEmail}><img src={emailIcon} /></a></span>
					</li>}
					{(clientDirection !== '') && <li className="client-direction-act">
					<a href={clientDirection} target="_blank"><img src={directIcon} /></a>
					</li>}
					</ul>
				   </div>);
		} else {
			return '';
		}
		
  }
	

    render() {

		return (<div className="pagesList-main-div">
		
				 
					 {/* Header start */}
					 <Header />
					 {/* Header End */}
					 
					 <div className="common-inner-blckdiv">
						<div className="common-inner-banner">
							<img src={innerBanner} />
							<p>{this.state.pagetitle_txt}</p>
						</div>
						{this.clientInfoTech()}
					 </div>
					 
					 <div className="cms-page">
						<div className="container-one cms-content">
							<div className="container cms-content">
							{this.state.pagedetail}
							</div>
						</div>
					  </div>
					  
					
				 
					<Footer />		
					<div id="dvLoading" className="dvLoadrCls"></div>
		       </div>);
    }
}



const mapStateToProps = (state) => {
	
	var pagedataRst = Array();
	if(Object.keys(state.pagedata).length > 0) {
		  if(state.pagedata[0].status === 'ok') {
			 pagedataRst = state.pagedata[0].result_set;
		  }
	}
	
	return {
		globalsettings: state.settings,
		pagedata: pagedataRst
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getSettings: () => {
		  dispatch({ type: GET_GLOBAL_SETTINGS });
		},
		getRequestpage: (slug) => {
		dispatch({ type: GET_REQUESTPAGEDATA, slug});
		},
	}
}

Pages.propTypes = {
	history: PropTypes.shape({
	push: PropTypes.func.isRequired
	}) 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));

 
