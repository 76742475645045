/* eslint-disable */
import React, { Component } from 'react';

import { withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import { fbAppId, baseUrl, appId, apiUrl, cateringId} from "../Helpers/Config";

import { validated } from 'react-custom-validation';
import validator from 'validator';

import DatePicker from 'react-datepicker';
import { setMinutes, setHours, format } from 'date-fns'
import 'react-datepicker/dist/react-datepicker.css';

const isEmpty = (value) =>
value === '' ? 'This field is required.' : null
const isEmail = (email) =>
validator.isEmail(email) ? null : 'This is not a valid email.'

const minLength = (password, length) =>
    password.length >= length ? null : 'Password must be at least 6 characters.'

const areSame = (password, rePassword) =>
    password === rePassword ? null : 'Passwords do not match.'

const isChecked = (terms) => terms ? null : "Please accept terms and conditions"


function validationConfigSignup(props) {

    const { firstname, lastname, birthday, email, password, rePassword, mobile, terms } = props.fields

    return {
        fields: ['firstname', 'lastname', 'birthday', 'email', 'password', 'rePassword', 'mobile', 'terms'],

        validations: {
            firstname: [
                [isEmpty, firstname]
            ],
            lastname: [
                [isEmpty, lastname]
            ],
			birthday: [
                [isEmpty, birthday]
            ],
            email: [
               [isEmail, email],
                /* [isUnique, email]*/
            ],
            password: [[minLength, password, 6]],
            rePassword: {
                rules: [[areSame, password, rePassword]],
                fields: ['password', 'rePassword']
            },
            mobile: [
                [isEmpty, mobile],
                [isMobile, mobile],
               /* [isPhonenumerExist, mobile]*/
            ],
            terms: [
                [isChecked, terms, 1],
            ],
        }
    }
}


const phonenumberPattern = /^[0-9]{6,14}$/;
const isMobile = (mobile) =>
    mobile.match(phonenumberPattern) ? null : 'please enter valid Mobile Number.'


const isUnique = (email) => {

    var qs = require('qs');
    var postObject = {
        "app_id": appId,
        "type": "web",
        "customer_email": email
    };

    axios.post(apiUrl + "customer/email_exist", qs.stringify(postObject)).then(response => {
        if (response.data.status === "ok") {
            document.getElementById("spn-email-error").innerHTML = '';
        } else {
            document.getElementById("spn-email-error").innerHTML = '<span class="error">Email already exists</span>';
        }
    });

}

const isPhonenumerExist = (mobile) => {
    var qs = require('qs');
    var postObject = {
        "app_id": appId,
        "type": "web",
        "customer_phone": mobile
    };
    axios.post(apiUrl + "customer/phoneno_exist", qs.stringify(postObject)).then(response => {
        if (response.data.status === "ok") {
            document.getElementById("spn-mobile-error").innerHTML = '';
        } else {
            document.getElementById("spn-mobile-error").innerHTML = '<span class="error">Mobile number already exists</span>';
        }
    });

}


class Signup extends Component {

    constructor(props) {
        super(props);

    }

    closepopup(path) {

		const{history} = this.props;
        history.push(path);
        $.magnificPopup.close();
    }
	
	handleChgDate(datevalue) {
        var dateval = new Date(datevalue);
		dateval = format(dateval, "dd/MM/yyyy");
        this.props.onChange("birthday",datevalue)
    }

    render() {

        const { fields, onChange, onValid, onInvalid, $field, $validation } = this.props
        const spanStyle = {
            clear: 'both'
        };
        let errMsgFirstName, errMsgLastName, errMsgBirthday, errMsgEmail, errMsgPassword, errMsgrePassword, errMsgMobile, errMsgTerms;

        if ($validation.firstname.error.reason !== undefined) {
            errMsgFirstName = ($validation.firstname.show && <span className="error">{$validation.firstname.error.reason}</span>)
        }
        if ($validation.lastname.error.reason !== undefined) {
            errMsgLastName = ($validation.lastname.show && <span className="error">{$validation.lastname.error.reason}</span>)
        }
		if ($validation.birthday.error.reason !== undefined) {
            errMsgBirthday = ($validation.birthday.show && <span className="error">{$validation.birthday.error.reason}</span>)
        }
        if ($validation.email.error.reason !== undefined) {
            errMsgEmail = ($validation.email.show && <span className="error">{$validation.email.error.reason}</span>)
        }
        if ($validation.password.error.reason !== undefined) {
            errMsgPassword = ($validation.password.show && <span className="error">{$validation.password.error.reason}</span>)
        }
        if ($validation.rePassword.error.reason !== undefined) {
            errMsgrePassword = ($validation.rePassword.show && <span className="error">{$validation.rePassword.error.reason}</span>)
        }
        if ($validation.mobile.error.reason !== undefined) {
            errMsgMobile = ($validation.mobile.show && <span className="error">{$validation.mobile.error.reason}</span>)
        }
        if ($validation.terms.error.reason !== undefined) {
            errMsgTerms = ($validation.terms.show && <span className="error">{$validation.terms.error.reason}</span>)
        }


        return (
            <div className="popup-body">
                <h4>Your Information</h4>
                <div className="form-group">
                    <div className="focus-out">
                        <label>First Name</label>
                        <input type="text" className="form-control input-focus" value={fields.firstname} {...$field('firstname', (e) => onChange('firstname', e.target.value)) } />
                        {errMsgFirstName}
                    </div>

                </div>
                <div className="form-group">
                    <div className="focus-out">
                        <label>Last Name</label>
                        <input type="text" className="form-control input-focus" value={fields.lastname} {...$field('lastname', (e) => onChange('lastname', e.target.value)) } />
                        {errMsgLastName}
                    </div>
                </div>
                <div className="form-group">
                    <div className="focus-out">
                        <label>Mobile Number</label>
                        <input type="tel" className="form-control input-focus" pattern="\d*" value={fields.mobile} {...$field('mobile', (e) => onChange('mobile', e.target.value)) } />
                        <div id="spn-mobile-error">{errMsgMobile}</div>


                    </div>
                </div>
				
				<div className="form-group">
						<div className="custom_select">
							<select name="gender" value={fields.gender} className="gender_selct signup-gender-selct" {...$field('gender', (e) => onChange('gender', e.target.value)) } id='gender' >
							<option value='M'>Male</option>
							<option value='F'>Female</option>
							<option value='O'>Unspecified</option>
							</select>
						</div>
                </div>
				
				<div className="form-group">
                    <div className={(fields.birthday != '' ? 'focus-out focused' : 'focus-out')}>
                        <label>Birthday</label>
                       <div className="react_datepicker">
						<DatePicker peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" 
							className="form-control input-focus" dateFormat="dd/MM/yyyy" selected={fields.birthday} onChange={this.handleChgDate.bind(this)} />

							{errMsgBirthday}
						</div>
						<span className="birthday_info_sp">[ <b>* Used to send vouchers and promotions.</b> ]</span>
                    </div>
                </div>
				
                <div className="form-group">
                    <h4 className="your_lgn_spacing">Your Login</h4>
                    <div className="focus-out">
                        <label>Email Address</label>
                        <input type="text" className="form-control input-focus" value={fields.email} {...$field('email', (e) => onChange('email', e.target.value)) } />
                        <div id="spn-email-error">{errMsgEmail}</div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="focus-out">
                        <label>Password</label>
                        <input type="password" className="form-control input-focus" value={fields.password} {...$field('password', (e) => onChange('password', e.target.value)) } />
                        {errMsgPassword}
                    </div>
                </div>
                <div className="form-group">
                    <div className="focus-out">
                        <label>Confirm Password</label>
                        <input type="password" className="form-control input-focus" value={fields.rePassword} {...$field('rePassword', (e) => onChange('rePassword', e.target.value)) } />
                        {errMsgrePassword}

                    </div>
                </div>
				
                <div className="form-group custom-pdpa-div">
                    <div className="custom_checkbox custom_checkbox_content">
                        <input type="checkbox" id="pdpa_consent" checked={fields.pdpa_consent}   {...$field('pdpa_consent', (e) => onChange("pdpa_consent", e.target.value), null, false) } /> <span></span>
                        <div className="sign_reg">
                            <p>Yes, I have read and accept the <a className="sign_reg_uline disbl_href_action" href="/">PDPA Consent</a>.</p>
                        </div>
                    </div>
                </div>
                
                <div className="form-group">
                    <div className="custom_checkbox custom_checkbox_content">
                        <input type="checkbox" id="terms" checked={fields.terms}   {...$field('terms', (e) => onChange("terms", e.target.value), null, false) } /> <span></span>
                        <div className="sign_reg">
                            <p>I consent to receive coupons and promotions news from Copper Chimney, its franchisees and affiliates, and understand and agree that my information will be used as described here and in Copper Chimney <a className="sign_reg_uline" onClick={(e) => { e.preventDefault(); this.closepopup('/page/privacy-policy') }} href="/">Privacy Policy</a> and <a className="sign_reg_uline" onClick={(e) => { e.preventDefault(); this.closepopup('/page/terms-and-conditions') }} href="/">Terms of Service.</a></p>
                        </div>

                    </div>
                    {errMsgTerms}
                </div>
                <div className="form-group">
                    <div className="login_pop_sub">

                        <button type="button" className="btn btn_black btn_minwid signup_submit" onClick={(e) => { e.preventDefault(); this.props.$submit(onValid, onInvalid); }}>Submit</button>
                    </div>
                </div>
				
				<div className="form-group-11">
                    <div className="controls single-link">
                        <a href="#login-popup"  className="open-popup-link">Back to login</a>
					</div>
                </div>

            </div>
        );
    }
}
Signup = validated(validationConfigSignup)(Signup)

export default withRouter(Signup);
