/* eslint-disable */
import React, { Component } from 'react';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { appId, apiUrl, cateringId, apiUrlV2, baseUrl, timThumpUrl } from "../../Helpers/Config"
import axios from 'axios';
import { getReferenceID, showLoader, hideLoader, stripslashes, callImage, showAlert, smoothScroll } from "../../Helpers/SettingHelper";
import { GET_CATEGORY_LIST } from '../../../actions';

/*import swichImg from "../../../common/images/swich.png";*/
import swichImg from "../../../common/images/switch-orange.png";
import NoimgImg from "../../../common/images/noimg-800x800.jpg";

class Categorylist extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cateVenueFlag: '', catePrimaryId: '', category_id: '', category_name: '', categorylist: [], commonimgurl: [], ownvenuecategory: [], catownvenueimgurl: [], hallcategory: [], cathallimgurl: []
        };
		
		this.props.getCategorylist();
    }
	
	componentWillReceiveProps(nxtProps) {
		
		if(Object.keys(nxtProps).length > 0) {
			var categoryStateArr = nxtProps.categoryState;
			for (var key in categoryStateArr) {
				this.state[key] = categoryStateArr[key];
			}
		}
		
		if(this.state.categorylist !== nxtProps.categorylist) {
			hideLoader('categorylist_norecord','class');
			this.setState({categorylist: nxtProps.categorylist, commonimgurl: nxtProps.catcommonimgurl});
		}
		
	}

    componentDidMount() {
		showLoader('categorylist_norecord','class');
	}
	
	getCategoryListHtml() {
		
		var categoryPryId = this.state.catePrimaryId;
		var catgPryId = cookie.load('catering_categoryPryId');
		categoryPryId = (categoryPryId === '') ? catgPryId : categoryPryId;
		
		if(this.state.categorylist.length > 0) {
			
			const Categoryhtml = this.state.categorylist.map((category, index) =>
								 <li className={(category.pro_cate_primary_id === categoryPryId)?"pkg_gcol catbox-bottom-list category-active":"pkg_gcol catbox-bottom-list"} key={index}>
								   <div className="pkg_gitem">
								     <div className="pkg_gimg catbox-bottom-img">
										{(category.pro_cate_image !== '') ? <img src={this.state.commonimgurl.cat_image_source+category.pro_cate_image} /> : <img src={NoimgImg} />}
										<div className="pkg_ginfo catbox-bottom-info">
											<h4>{stripslashes(category.pro_cate_name)}</h4>
											<p>{(category.pro_cate_short_description !== '') ? stripslashes(category.pro_cate_short_description) : ""}</p>
										</div>
								     </div>
									 <div className="pkg_gbtsec text-center catbox-bottom">
										<a href="javascript:void(0)" onClick={this.saveCategoryDetails.bind(this, category)} className="button ordernw-but" title="Order Now">Order Now</a>
									 </div>
								   </div>
								  </li>); 
			return Categoryhtml;					  
								  
		} else {
			return (<div className="categorylist_norecord"> Catering category was not available. </div>);
		}
		
	}
	
	
	saveCategoryDetails(categoryDet) {

		if(Object.keys(categoryDet).length > 0) {
			
			this.props.sateValChangefun('catePrimaryId', categoryDet.pro_cate_primary_id);
			this.props.sateValChangefun('category_id', categoryDet.pro_cate_id);
			this.props.sateValChangefun('category_name', stripslashes(categoryDet.pro_cate_name));
			this.props.sateValChangefun('venueTypeChanged', 'Yes');
			
			cookie.save('catering_categoryPryId', categoryDet.pro_cate_primary_id);
			cookie.save('catering_categoryId', categoryDet.pro_cate_id);
			cookie.save('catering_categoryName', stripslashes(categoryDet.pro_cate_name));
			smoothScroll(100,50);
			
			this.setState({catePrimaryId: categoryDet.pro_cate_primary_id, category_id: categoryDet.pro_cate_id, category_name: stripslashes(categoryDet.pro_cate_name)}, function () { this.props.setActiveTab(3); }.bind(this));
			
		}
	}
	
	
    /* ViewProducts */
	

    render() {

        return (<div>	
		           <div className="tab_mobrow" style={{display:this.props.showsActiveDiv(2)}}>
						<div className="pkg_gridsec" style={{paddingTop:'10px'}}>
						
						 <div className="innerproduct">
							<ul className="row-pgk-list">
								{this.getCategoryListHtml()}
							</ul>
						 </div>
							
						</div>	
					</div>
				</div>);
     }
}

const mapStateTopProps = (state) => {
  console.log('kokokokok', state.categorylist);	
  var categoryRst = Array();var commonImg = Array();
  if(Object.keys(state.categorylist).length > 0) {
	  if(Object.keys(state.categorylist[0].result_set).length > 0) {
		 categoryRst = state.categorylist[0].result_set;
		 commonImg = state.categorylist[0].common;
	  }
  }
  
  return {
    categorylist: categoryRst,
    catcommonimgurl: commonImg
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCategorylist: () => {
      dispatch({ type: GET_CATEGORY_LIST });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Categorylist));
