/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { proFolderUrl } from "../Helpers/Config";
import { stripslashes, showPriceValue, callImage } from "../Helpers/SettingHelper";
import { GET_MENU_NAVIGATION } from '../../actions';
import noimage from "../../common/images/no-img-product.png";

class MenuNavigation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: []
    }
	
  }

  componentWillReceiveProps(pdtProps) {
	  if(Object.keys(this.props.navigateMenu).length > 0) {
		setTimeout(function(){ if ($(".menu-section-inner").length > 0) {
				$(".menu-section-inner").mCustomScrollbar();
		} }, 800);
	  }
  }
  
  load_mcustScrl() {
		let navigateMenuArr = this.props.navigateMenu;
		if(Object.keys(navigateMenuArr).length > 0) {
			setTimeout(function(){ if ($(".menu-section-inner").length > 0) {
					$(".menu-section-inner").mCustomScrollbar();
			} }, 2000);
		}
  }
  
  navigateMenuList(){
       
	    let navigateMenuArr = this.props.navigateMenu;
	    var selectedNav = this.props.productState.selectedNavigation;
		
		if(Object.keys(navigateMenuArr).length > 0) {
			
			const mainMenu = navigateMenuArr.map((loaddata, index) => {
				if (loaddata.menu_type == "main") {
					return (<div key={index+1} className={(selectedNav === loaddata.pro_cate_slug)?"menu-list-intvl active":"menu-list-intvl"}>
								<Link to={"/products/category/" + loaddata.pro_cate_slug} className="menu-title-link" title={loaddata.menu_custom_title} >
								<span className="menu-img">{(loaddata.pro_cate_image !== '' && loaddata.pro_cate_image !== null)?<img src={proFolderUrl+'category-image/'+loaddata.pro_cate_image} />:<img src={noimage} />}</span>
								<span className="menu-title-txt">{ stripslashes(loaddata.menu_custom_title.toLowerCase()) }</span>
								</Link>
						  </div>);
				} else if (loaddata.menu_type == "sub") {
					return (<div key={index+1} className={(selectedNav === loaddata.pro_subcate_slug)?"menu-list-intvl active":"menu-list-intvl"}>
								
								<Link to={"/products/subcategory/" + loaddata.pro_subcate_slug} className="menu-title-link" title={loaddata.menu_custom_title} >
								<span className="menu-img">{(loaddata.pro_subcate_image !== '')?<img src={proFolderUrl+'subcategory-image/'+loaddata.pro_subcate_image} />:<img src={noimage} />}</span>
								<span className="menu-title-txt">{ stripslashes(loaddata.menu_custom_title.toLowerCase()) }</span>
								</Link>
						  </div>);
				}
			});
			
			return mainMenu;
			
		} else {
			
			return ;
		}
		

   }
  
  componentDidMount() {
		/*const script = document.createElement("script");
		script.src = "/js/jquery.mCustomScrollbar.concat.min.js";
		script.async = true;
		document.body.appendChild(script);*/
  }

  render() {
    return (<div className="menu-section-left">
				<div className="menu-section-inner">
				  {this.navigateMenuList()}
				  {this.load_mcustScrl()}
				</div>  
			</div>)
  }

}

export default (MenuNavigation);