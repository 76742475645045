/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link} from 'react-router-dom';
import Slider from "react-slick";

import { stripslashes } from "../../Helpers/SettingHelper";
import whiteLogoImg from "../../../common/images/white-logo.png";
import homeBannerImg from "../../../common/images/home-banner.jpg";
import { GET_BANNER_LIST } from '../../../actions';

var Parser = require('html-react-parser');

class HomeBanner extends Component {
	  constructor(props) {
		super(props);
	  }
	  
	  componentWillMount() {
		this.props.getBannerList();
	  }
	  
	  componentDidMount() {
		/*console.log('DidMount');*/
	  }
	  
	  /* componentDidUpdate() {
		console.log('this called last - after page loading');
	  } */
	  
	  render() {
		  
		let bannerArr = this.props.banner;
		let bannerlist = [];
		let bannerimagesource = '';

		if(Object.keys(bannerArr).length > 0) {
			if(bannerArr[0].status === 'ok') {
				bannerlist = bannerArr[0].result_set;
				bannerimagesource = bannerArr[0].common.image_source;
			}
		}  
		  
		var settingsGallery = {
			infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
			autoplay: true
        };
		
		return (
			    <div className="home-banner">
					{(Object.keys(bannerlist).length > 0)?<Slider {...settingsGallery}>
						   {
							  (bannerlist).map(
							  (banner,index) => {
								  return (
									<div key={index}>
										<img src={bannerimagesource + banner.banner_image} alt="Banner" />
										<div className="banner-info">
											{/*<h2>{stripslashes(banner.banner_name)}</h2>*/}
											{/*<div className="white-logo-img"><img src={whiteLogoImg} alt="whitelogo" /></div>*/}
											<p>{(banner.banner_description!='')?Parser(stripslashes(banner.banner_description)):''}</p>
											<Link to={"/products"} className="button btn-act-clss" title="Order Now">Order Now</Link>
										</div>   
									</div>
								  )
								 }
								) 
						   }
					</Slider>:
					<div className="loader-main-cls">
						<img src={homeBannerImg} alt="Banner" />
						<div className="loader-sub-div"></div>
					</div>}
				</div>
					
			   )
	  }	
}

const mapStateTopProps = (state) => {
  return {
    banner: state.banner
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBannerList: () => {
      dispatch({ type: GET_BANNER_LIST });
    },
  }
}

export default connect(mapStateTopProps, mapDispatchToProps)(HomeBanner);
