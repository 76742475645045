/* Live */
export const appId        = "97440967-AC09-422A-B5C2-BBA4D8C3D989";

export const apiUrl       = "https://ccpl.ninjaos.com/api/";
export const apiUrlV2     = "https://ccpl.ninjaos.com/apiv2/";
export const apiUrlPro    = "https://ccpl.ninjaos.com/ninjapro/";
export const apiUrlCtrng  = "https://ccpl.ninjaos.com/cateringv2/";
export const apiUrlNotify = "https://ccpl.ninjaos.com/Pushorder/";
export const baseUrl      = "https://www.copperchimney.com.sg/";
export const timThumpUrl  = "https://ccpl.ninjaos.com/timthumb.php?src=";
export const blogImageUrl = "https://ccpl.ninjaos.com/media/dev_team/blog/";
export const proFolderUrl = "https://ccpl.ninjaos.com/media/dev_team/products/";



/* Dev */
/*export const apiUrl       = "https://ccpl.ninjaos.com/development/api/";
export const apiUrlV2     = "https://ccpl.ninjaos.com/development/apiv2/";
export const apiUrlPro    = "https://ccpl.ninjaos.com/development/ninjapro/";
export const apiUrlCtrng  = "https://ccpl.ninjaos.com/development/cateringv2/";
export const apiUrlNotify = "https://ccpl.ninjaos.com/development/Pushorder/";
export const baseUrl      = "http://uat.copperchimney.com.sg/";
export const timThumpUrl  = "https://ccpl.ninjaos.com/timthumb.php?src=";
export const blogImageUrl = "https://ccpl.ninjaos.com/development/media/dev_team/blog/";
export const proFolderUrl = "https://ccpl.ninjaos.com/development/media/dev_team/products/";*/

/*export const apiUrl       = "http://ccpl.jankosoftworks.com/api/";
export const apiUrlV2     = "http://ccpl.jankosoftworks.com/apiv2/";
export const apiUrlPro    = "http://ccpl.jankosoftworks.com/ninjapro/";
export const apiUrlNotify = "http://ccpl.jankosoftworks.com/Pushorder/";
export const baseUrl      = "http://uat.copperchimney.com.sg/";
export const timThumpUrl  = "http://ccpl.jankosoftworks.com/timthumb.php?src=";
export const blogImageUrl = "http://ccpl.jankosoftworks.com/media/dev_team/blog/";
export const proFolderUrl = "http://ccpl.jankosoftworks.com/media/dev_team/products/";*/

export const stripeImage  = "/img/stripe_logo.png";

export const deliveryId  = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId    = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const cateringId    = "EB62AF63-0410-47CC-9464-038E796E28C4";
export const reservationId    = "79FA4C7F-75A1-4A95-B7CE-81ECA2575363";

export const CountryTxt   = "Singapore";
export const productNoImg = "/img/product-noimg.jpg";
export const stripeCompany    = "Copper Chimney";

export const fbAppId    = "391167724611009";

export const stripeKey    = "pk_test_hLfht4KEShHlACDG9mBfrPkv";
export const stripeEmail    = "dev@jankosoft.com";
export const stripeReference    = "copper";
export const stripeDesc    = "My Checkout";
export const stripeCurrency    = "SGD";
 
