/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import { appId, apiUrl, apiUrlV2, deliveryId, timThumpUrl } from "../Helpers/Config";
import { getReferenceID, stripslashes, showPriceValue, callImage, showLoader, hideLoader, showCustomAlert, showCartLst, showAlert, removePromoCkValue } from "../Helpers/SettingHelper";
import noimage from "../../common/images/no-img-product.png";

import { GET_PRODUCT_LIST } from '../../actions';
var Parser = require('html-react-parser');
var qs = require('qs');

class ProductList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedNavigation: '',
      selectedslugType: '',
      productSubcatlist: [],
      productCommon: []
    }
	
  }

  componentWillReceiveProps(nextProps){
	    let selectedNavigation = nextProps.productState.selectedNavigation;
	    let slugType = nextProps.productState.catslugType;
		if(selectedNavigation!==this.state.selectedNavigation){
		  if(selectedNavigation !== '') {
			  var catSlug = selectedNavigation;
			  var subcatSlug = '';
			  if(slugType === 'subcategory') {
				  catSlug = '';
			      subcatSlug = selectedNavigation;
			  }
			  this.props.getProductList(catSlug,subcatSlug,'');
		  }
		  this.setState({selectedNavigation: selectedNavigation,selectedslugType: slugType});
		}
  }
  
  productsubcatlist() {
	  var subcatlistArr = this.props.productSubcatlist;
	  if(Object.keys(subcatlistArr).length > 0) {
		  hideLoader('productlist-main-div','class');
		  const productDetails = subcatlistArr.map((categories, categoryIndex) => {
			if (categories.products.length > 0) {
				return (<div className="innerproduct" key={categoryIndex}>
								<h3 id={categories.pro_subcate_slug}>{stripslashes(categories.pro_subcate_name)}</h3>
								<ul className="products-list-ulmain">
								{this.productList(categories.products, categories.products.length)}
								</ul>
						</div>);
			}
		});
		return productDetails;
	  } else {
		  return '';
	  }
  }
  
  chkProStockCls(proSlug,Stock) {
	  
	  var searchProVal = this.props.productState.searchProVal;
	  var actClstxt = (searchProVal === proSlug) ? ' active' : '';
	  
	  var returnText = "products-single-li no-stock-product " + proSlug + actClstxt;
	  if(Stock > 0) {
		  returnText = "products-single-li " + proSlug + actClstxt;
	  }
	  
	  return returnText;
  }
  
  /* Products List */
  productList(list, productcount) {
	  var imageSource = this.props.productCommon.product_image_source;
	  var tagImageSource = this.props.productCommon.tag_image_source;
	  var slugType = this.state.selectedslugType;
	  var naviSlug = this.state.selectedNavigation;
	  var clientProductStock = '';
	  if(Object.keys(this.props.globalsettings).length > 0) {
		if(this.props.globalsettings[0].status === 'ok') {
		  var globalSettingsArr = this.props.globalsettings[0].result_set;
		  if(Object.keys(globalSettingsArr).length > 0) {
			  clientProductStock = globalSettingsArr.client_product_stock;
		  }			  
		}
	  }
	  
	  
	  const listProduct = list.map((productDetail, index) => {
			
			var description = (productDetail.product_short_description !== null && productDetail.product_short_description !== '') ? Parser(stripslashes(productDetail.product_short_description)) : '';

			var prodivId = "proIndex-"+productDetail.product_primary_id;
			var comboProId = "comboPro-"+productDetail.product_slug;

			return (<li className={this.chkProStockCls(productDetail.product_slug,productDetail.product_stock)} id={prodivId} key={index} >
					<div className="products-image-div">
						{(productDetail.product_thumbnail !== '')?<img src={imageSource+'/'+productDetail.product_thumbnail} />:<img src={noimage} />}
					</div>

					<div className="product-info-div">
					  <div className="product-title-maindiv">
						   <div className="product-title">
							 <h3>{productDetail.product_alias !== '' ? stripslashes(productDetail.product_alias) : stripslashes(productDetail.product_name)}</h3>
						   </div>
						   {(Object.keys(productDetail.product_tag).length > 0)?<div className="product-tag-list">
							 <ul>
							  { 
								(productDetail.product_tag).map(
								  (producttag,index1) => {
								  return (<li key={index1}>{(producttag.pro_tag_image !== '')?<img src={tagImageSource+producttag.pro_tag_image} />:''}</li>) 
								 })
							  }
							 </ul>
						   </div>:''}
					   </div>

					   <div className="product-short-description"><p>{(description!=='')?description:Parser('&nbsp;')}
					   </p></div>
					   
					   <div className="product-price">
							<h3>{showPriceValue(productDetail.product_price)}</h3>
					   </div>
					   
					   <div className="products-ordernow-action">
					   {(productDetail.product_stock > 0 || productDetail.product_stock === null || clientProductStock !== '1')?((productDetail.product_type === '1')?<a className="button order_nowdiv smiple_product_lk disbl_href_action" href="/" onClick={this.addToCartSimple.bind(this,productDetail,'initial')}>Add to Cart</a>:<a href="/" onClick={this.viewProDetail.bind(this,productDetail)} title="Product Details" id={comboProId} className="button order_nowdiv compo_product_lk">Order Now</a>):<a className="button order_nowdiv disabled disbl_href_action" href='/'>Sold Out</a>}
						
						<div className="addcart_row addcart_done_maindiv">
							<div className="qty_bx">
								<span className="qty_minus" onClick={this.proQtyAction.bind(this,productDetail.product_primary_id, 'decr')}>-</span>
								<input type="text" className="proqty_input" readOnly value="1" />
								<span className="qty_plus" onClick={this.proQtyAction.bind(this,productDetail.product_primary_id, 'incr')}>+</span>
							</div>
							<button className="btn btn_black" onClick={this.addToCartSimple.bind(this,productDetail,'done')}>Done</button>
						</div>
					   </div>
					   
					</div>
				</li>);
			});
		
		return listProduct;
  }
  
  proQtyAction(indxFlg, actionFlg) {
	  var proqtyInput = $("#proIndex-"+indxFlg).find('.proqty_input').val();
		  proqtyInput = parseInt(proqtyInput);
	  if (actionFlg === 'decr') {
		  proqtyInput = (proqtyInput > 1) ? proqtyInput - 1 : proqtyInput;
	  } else {
		  proqtyInput = proqtyInput + 1;
	  }
	  $("#proIndex-"+indxFlg).find('.proqty_input').val(proqtyInput);
  }
  
  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
	
	event.preventDefault();
	var IndexFlg = productDetail.product_primary_id;
	
	if (actionFlg === 'initial') {
		$("#proIndex-"+IndexFlg).addClass('active');
		$("#proIndex-"+IndexFlg).find('.smiple_product_lk').hide();
		$("#proIndex-"+IndexFlg).find('.addcart_done_maindiv').show();
		return false;
	} else {
		showLoader('proIndex-'+IndexFlg,'Idtext');
		var availabilityId = cookie.load("defaultAvilablityId");
		/*var availabilityId = deliveryId;*/
		var availabilityName = (availabilityId === deliveryId) ? "Delivery" : "Pickup";
		var isAddonProduct = 'No';
		var productId = productDetail.product_id
		var customerId = (typeof cookie.load('UserId') === 'undefined') ? '' : cookie.load('UserId');
		var proqtyQty = $("#proIndex-"+IndexFlg).find('.proqty_input').val();
		
		var postObject = {};
		postObject = {
			'app_id': appId,
			"product_id": productId,
			"product_qty": proqtyQty,
			"product_type": 1,
			"availability_id": availabilityId,
			"availability_name": availabilityName,
			"isAddonProduct": isAddonProduct,
			"reference_id":  (customerId === '') ? getReferenceID() : '',
			"customer_id": customerId
		};

		Axios.post(apiUrlV2 + "cart/simpleCartInsert", qs.stringify(postObject)).then(res => {
			$("#proIndex-"+IndexFlg).removeClass('active');
			hideLoader('proIndex-'+IndexFlg,'Idtext');
			$("#proIndex-"+IndexFlg).find('.addcart_done_maindiv').hide();
		    $("#proIndex-"+IndexFlg).find('.smiple_product_lk').show();
			if (res.data.status === "ok") {
				this.props.sateValChange('cartflg', 'yes');
				removePromoCkValue();
				showCustomAlert('success','Great choice! Item added to your cart.');
				/*showCartLst();*/
				this.handleShowAlertFun('success','Great choice! Item added to your cart.');
			} else if (res.data.status === "error") {
				var errMsgtxt = (res.data.message !== '') ? res.data.message : "Sorry! Products can`t add your cart.";
				showCustomAlert('error',errMsgtxt);
				this.handleShowAlertFun('Error',errMsgtxt);
			}
			
            return false;
			
		});
		
	}
	
  }
  
  viewProDetail(productDetail, event) {
	  event.preventDefault();
	  var productSlug = productDetail.product_slug;
	  if(productSlug !== '') {
		$("#proIndex-"+productDetail.product_primary_id).addClass('active');
		this.props.sateValChange('view_pro_data', productSlug);
	  }
	  return false;
  }
  
  handleShowAlertFun(header,msg) {
		var magnfPopup = $.magnificPopup.instance;
		showAlert(header, msg, magnfPopup);
		$.magnificPopup.open({
		  items: {
			src: '.alert_popup'
		  },
		  type: 'inline'
		});
  }
	
  componentDidMount() {
	/*console.log('two');*/
  }

  render() {
    return (<div className="productlist-main-div" >
		     
			 <div className="innerproduct_row">
				{this.productsubcatlist()}
			 </div>
			
		  </div>)
  }

}

const mapStateTopProps = (state) => {
  var tempArr = Array();var productSubcat = Array();var productCmn = Array();
  if(Object.keys(state.product).length > 0) {
	  var tempArr = (!('productlist' in state.product[0])) ? Array() : state.product[0].productlist;
	  if(Object.keys(tempArr).length > 0) {
		 if(tempArr[0].status === 'ok') {
			 productSubcat = tempArr[0].result_set[0].subcategorie;
			 productCmn = tempArr[0].common;
		 }
	  }
  } 
  
  return {
    productSubcatlist: productSubcat,
    productCommon: productCmn
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProductList: (catslug, subcatslug, outletid) => {
      dispatch({ type: GET_PRODUCT_LIST, catslug, subcatslug, outletid });
    },
  }
}

export default connect(mapStateTopProps, mapDispatchToProps)(ProductList);